<template src="./DatabaseConnection.html"></template>

<script>

export default {
    name: 'database-connection',
    methods: {
        routeToMyProfile() {
            this.$router.push({ name: 'userProfile' })
        },
    }
};
</script>
