<template src="./headersPage.html"></template>

<script>
import databaseConnection from '../../../components/DatabaseConnection/DatabaseConnection.vue';

export default {
    name: 'headers-page',
    components: {
        'database-connection': databaseConnection,
    },
    data() {
        return {
            accuracyOfGpsValues: [],
            activeTab: 1,
            coordinatesPageNumber: 0,
            coordinateSystemValues: [],
            dropdownFields: { text: 'Text', value: 'Value' },
            headers: {},
            headersComparer: null,
            mode: '',
            selectedTemplate: '',
			sessionID: null,
            showRequiredOutline: false,
            tabAnimation: {
                previous: { effect: 'None', duration: 0, easing: '' },
                next: { effect: 'None', duration: 0, easing: '' },
            },
            tabsInRow: 12,
            uniqueCode: null,
            unsavedChanges: false,
            verticalDatumValues: [],
        };
    },
    watch: {
        headers: {
            deep: true,
            handler(newVal, oldVal) {
                if (!DMUtils.isObjectEmpty(oldVal)) {
                    if (JSON.stringify(newVal) !== JSON.stringify(this.headersComparer)) {
                        this.unsavedChanges = true;
                        this.$store.commit('setUseNavigationWarning', true);
                    }
                }
            },
        },
    },
    computed: {
        userIsReadOnly() {
            if (
                this.$store.state.AuthorizationModule.SignedInUser.Role === 'Admin' ||
                this.$store.state.AuthorizationModule.SignedInUser.Role === 'User'
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        btnCloseOnClick() {
            if (this.unsavedChanges && !this.userIsReadOnly) {
                DMUtils.confirmClose(this.navigateToPreviousPage);
            } else {
                this.navigateToPreviousPage();
            }
        },
        btnQuickFillOnClick() {
            this.$store.dispatch('SessionsModule/getQuickFill', this.selectedTemplate)
				.then(() => {
					this.setHeaders();
				});
        },
        btnSaveOnClick() {
            let updatedSession = DMUtils.copy(this.headers);
            updatedSession.SessionPages.pop();
            this.showRequiredOutline = true;

            updatedSession.SessionPages.forEach((page) => {
                if (page.Fields) {
                    page.Fields.forEach((field) => {
                        if (field.InputDataType === 'Date') {
                            if (field.Value) {
                                let date = moment(field.Value).format('M/DD/YYYY h:mm A');
                                field.Value = date;
                            } else {
                                field.Value = null;
                            }
                        }
                    });
                }
            });

            let action;
            let payload = updatedSession;

            if (this.mode === 'Edit') {
                action = 'SessionsModule/updateSession';
            } else {
                action = 'SessionsModule/createSession';
            }

            this.$store.dispatch(action, payload)
				.then((response) => {
					if (response) {
						this.setHeaders()
					}
				})
                .then(() => {
                    this.resetChanges();
                    this.mode = 'Edit';
                    this.$router.push({ name: 'headers', query: { id: response.SessionID }});
                    DMUtils.alertShow('Changes were saved successfully.', 'Success');
                })
				.catch((error) => {
					this.handleFieldError(error.response.data.ErrorDetail)
				})
        },
        changeTab(pageNumber) {
            this.activeTab = pageNumber;
        },
        getCoordinatesPageDropdowns() {
            this.$store.dispatch('SessionsModule/getAccuracyOfGpsValues', this.selectedTemplate)
				.then((response) => {
					this.accuracyOfGpsValues = response;
					return this.$store.dispatch('SessionsModule/getCoordinateSystemValues', this.selectedTemplate);
				})
				.then((response) => {
					this.coordinateSystemValues = response;
					return this.$store.dispatch('SessionsModule/getVerticalDatumValues', this.selectedTemplate);
				})
				.then((response) => {
					this.verticalDatumValues = response;

                    this.resetChanges();
				});
        },
        getHeadersForTemplate(args) {
            this.selectedTemplate = args.itemData.Value;
            this.activeTab = 0;

            this.$store.dispatch('SessionsModule/getNewSession', this.selectedTemplate)
				.then(() => {
					this.activeTab = 1;
					this.setHeaders();
					this.resetChanges();
					this.unsavedChanges = false;
				});
        },
        getInputClasses(field) {
            let classes = 'posm-input-lg dm-margin-bottom-sm';

            if (field.IsRequired) {
                classes += ' required';
            }

            if (field.IsRequired && this.showRequiredOutline) {
				if (field.Value === '' || field.Value === null) {
					classes += ' required-outline';
				}
            }

            return classes;
        },
        getNumericFormat(field) {
            if (field.LabelText.toLowerCase().includes('year')) {
                return '####';
            }
            if (field.InputDataType === 'Decimal' && !field.LabelText.toLowerCase().includes('year')) {
                return 'N2';
            }

            return 'N';
        },
		getDecimalPrecision(field) {
			if (field.InputDataType === 'Decimal') {
				return 2;
			}

			return 0;
		},
		handleFieldError(error) {
			let errorFocusTimer = setInterval(() => {
				if (this.$store.state.ModalModule.ModalsOpened == 0) {
					this.changeTab(error.PageNumber);
					let id = 'field' + error.FieldNumber;

					//Allow time to change the tab
					setTimeout(() => {
						this.$refs[id][0].ej2Instances.focusIn();
					}, 200)

					clearInterval(errorFocusTimer);
				}
			}, 250);
		},
        navigateToPreviousPage() {
			let fromPage = this.$store.state.SessionsModule.NavigatedToHeadersFrom;

			if (fromPage === 'observations') {
				this.$router.push({ name: fromPage, query: { id: this.$store.state.SessionsModule.Session.SessionID } });
			} else {
				this.$router.push({ name: 'sessionManagement' });
			}
        },
        resetChanges() {
            this.headersComparer = DMUtils.copy(this.headers);
            this.unsavedChanges = false;
            this.$store.commit('setUseNavigationWarning', false);
        },
        selectTemplate(args) {
            if (args.isInteracted) {
                if (this.unsavedChanges) {
                    args.cancel = true;
                    const title = 'Warning';
                    const message =
                        'You will lose your unsaved changes if you change the template. Do you wish to continue?';

                    DMUtils.confirmShow(message, title, () => this.getHeadersForTemplate(args), null, 'Yes', 'No');
                } else {
                    this.getHeadersForTemplate(args);
                }
            }
        },
        setHeaders() {
            return new Promise((resolve) => {
                this.headers = this.$store.state.SessionsModule.Session;
    
                let lastPage = this.headers.SessionPages[this.headers.SessionPages.length - 1];
                let coordinatesPageNumber;
    
                if(lastPage.PageName !== 'Coordinates') {
                    coordinatesPageNumber = lastPage.PageNumber + 1;
                    this.headers.SessionPages.push({ PageName: 'Coordinates', PageNumber: coordinatesPageNumber });
                } else {
                    coordinatesPageNumber = lastPage.PageNumber;
                }
    
                this.headers.SessionPages.forEach((page) => {
                    if (page.Fields) {
                        page.Fields.forEach((field) => {
                            if (field.InputDataType === 'Date') {
                                if (!field.Value) {
                                    field.Value = null;
                                }
                            }
    
                            if (field.InputType === 'Checkbox') {
                                if (typeof field.Value === 'string') {
                                    if (field.Value.toLowerCase() === 'true' || field.Value.toLowerCase() === 'yes') {
                                        field.Value = true;
                                    } else {
                                        field.Value = false;
                                    }
                                }
                            }
    
                            if (field.InputDataType === 'Decimal') {
                                field.Value = parseFloat(field.Value);
                            }
    
                            if (field.InputDataType === 'Numeric') {
                                field.Value = parseFloat(field.Value);
                            }
    
                            if (field.InputType === 'Dropdown') {
                                if (!field.Value) {
                                    field.Value = null;
                                }
                            }
                        });
                    }
                });
    
                this.coordinatesPageNumber = coordinatesPageNumber;
                this.selectedTemplate = this.headers.TemplateName;
                this.uniqueCode = this.headers.CoordinateInformation.PosmUniqueCode;

                return resolve();
            })
        },
    },
    created() {
		this.$store.commit('setUseNavigationWarning', false)
		this.$store.dispatch('getTemplates')
			.then(() => {
				let dispatch = '';
				let params = '';

				if (this.$route.query.id) {
					this.mode = 'Edit';
					dispatch = 'SessionsModule/getSessionByID';
					params = this.$route.query.id;
				} else {
					this.mode = 'Add';
					dispatch = 'SessionsModule/getNewSession';
				}

				return this.$store.dispatch(dispatch, params);
			})
			.then(() => {
				this.sessionID = this.$store.state.SessionsModule.Session.SessionID;
				this.setHeaders()
			})	
            .then(() => {
                    this.resetChanges();
                    this.getCoordinatesPageDropdowns();
                })			
    },
};
</script>
