<template src="./setHotKeyModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'set-hotkey-modal',
    mixins: [dmModalMixin],
    data() {
        return {
			dto: {}
        }
    },
    methods: {
		btnSaveOnClick() {
			if (this.dto.Name.length > 10) {
				DMUtils.alertShow('Name must be 10 or fewer characters.', 'Error');
				return;
			}

			this.$store.dispatch('SessionsModule/updateSavedSearch', this.dto)
				.then(() => {
					this.successClose();
				})
		}
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
}
</script>