<template src="./addVideoModal.html"></template>

<script>
import dmModalMixin from '../../../../../../dmFramework/dmJS/dmModalMixin';
import dmUtils from '../../../../../../dmFramework/dmJS/dmUtils';
export default {
    name: 'manage-videos-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            fileSize: 0,
            dto: {
                Name: '',
                VideoSrc: {},
            },
            path: {
                saveUrl: window.apiRoot + '/Sessions/AddVideo?SessionID=' + this.$store.state.ObservationModule.Session.SessionID,
                chunkSize: window.chunkSize
            },
            buttons: {
                browse: 'Browse',
                clear: 'Clear',
                upload: 'Save'
            }
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
          return 'Add Video'
        },
		sessionID() {
			return this.$store.state.ObservationModule.Session.SessionID;
		}
    },
    methods: {
        fileSelected() {
            this.fileSize = document.getElementById("videoFile").files.item(0).size;
        },
        fileUploading(args) {
            this.addHeaders(args);

            document.getElementById("close-button").disabled = true;
            document.getElementsByClassName(".e-btn.e-dlg-closeicon-btn").disabled = true;
        },
        fileFailure(args) {
            let errorMessage = JSON.parse(args.e.currentTarget.responseText)
            dmUtils.alertShow(errorMessage.Error, "Error")
            this.enableButtons();
        },
        fileSuccess() {
            this.$store.dispatch('SessionsModule/getSessionByID', this.sessionID, {root: true})
				.then((response) => {
                    this.$store.commit('ObservationModule/setSession', response);
                    this.successClose();
				});  
        },
        enableButtons() {
            document.getElementById("close-button").disabled = false;
        },
        addHeaders(args) {
            args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + dmUtils.getAuthToken());
            args.currentRequest.setRequestHeader('file-size', this.fileSize);
        },
        onClear() {

        },
        btnCloseOnClick() {
			this.successClose();
        },
    },
}
</script>