<template src="./pipe.html"></template>
<script>
export default {
    name: 'pipe',
    data() {
        return {
        };
    },
	computed: {
		pipeNodes() {
			let pipeNodes = this.$store.state.ObservationModule.PipeNodes;
			let finalNodes = [];

			pipeNodes.map((node) => {
				let convertedNodes = this.convertPipeNodeForDisplay(node);
				convertedNodes.map((node) => {
					finalNodes.push(node);
				});
			});

			return finalNodes;
		},
	},
    methods: {
        convertPipeNodeForDisplay(node) {
            let listFinalizedNodes = [];

            if (node.Observations.length == 0) {
                node.FileName = 'Fault.svg';
                listFinalizedNodes.push(node);
            } else {
                node.Observations.map((observation, index) => {
                    let newNode = DMUtils.copy(node);

                    // Here we are clearing the observations on a node and only adding the single observation this node is for
                    // in order for each observation to have its own image/node on the pipe

                    newNode.Observations = [];
                    newNode.Observations.push(observation);

                    let severity = 0;
                    let direction = '';

                    severity = observation.Severity;
                    direction = observation.Direction;

                    newNode.FileName = 'Fault';

                    if (severity != '0') {
                        newNode.FileName += '-' + severity;
                    }

                    newNode.FileName += '.svg';
                    newNode.Direction = direction;

                    listFinalizedNodes.push(newNode);
                });

                let firstObservation = node.Observations[0];
                node.Observations = [];
                node.Observations.push(firstObservation);
            }

            return listFinalizedNodes;
        },
		getPipeNodeImage(node) {
            let cssClasses = 'pipe-image ';
            if (node.FileName == 'Fault.svg') {
                cssClasses += 'pipe-fault';
            } else if (node.FileName == 'Fault-1.svg') {
                cssClasses += 'pipe-fault-1';
            } else if (node.FileName == 'Fault-2.svg') {
                cssClasses += 'pipe-fault-2';
            } else if (node.FileName == 'Fault-3.svg') {
                cssClasses += 'pipe-fault-3';
            } else if (node.FileName == 'Fault-4.svg') {
                cssClasses += 'pipe-fault-4';
            } else if (node.FileName == 'Fault-5.svg') {
                cssClasses += 'pipe-fault-5';
            }

            return cssClasses;
        },
		getPipeObservationFaultName(node) {
            if (node.Observations.length == 0) {
                return '';
            } else if (node.Observations.length == 1) {
                return node.Observations[0].FaultName;
            } else {
                let faultNames = [];
                node.Observations.map((observation) => {
                    faultNames.push(observation.FaultName);
                });

                return faultNames.join(', ');
            }
        },
		getPipeObservationText(node) {
            if (node.Observations.length == 0) {
                return '';
            } else if (node.Observations.length == 1) {
                return this.getTextForNode(node.Observations[0]);
            } else {
                let strings = [];
                node.Observations.map((observation) => {
                    strings.push(this.getTextForNode(observation));
                });

                return strings.join(', ');
            }
        },
		getTextForNode(observation) {
            let distance = observation.Distance;
            let faultCode = observation.FaultCode;

            return `(${distance}) - ${faultCode}`;
        },
		        jumpToSpotInVideoByNode(node) {
            // Each node will only have 1 observation now, no need to know a direction to find the right observation to jump to

            if (node.Observations.length == 0) {
                return;
            }

            let timestamp = node.Observations[0].VideoPosition;
            document.getElementById('observationVideo').currentTime = timestamp;
			this.$store.commit('ObservationModule/setSelectedPipeNode', node.Observations[0]);
        },
    },
    created() {

    },
};
</script>
