<template src="./advancedSearch.html"></template>
<script>
import Vue from 'vue';
import { getComponent } from "@syncfusion/ej2-base";
import { Tooltip, TooltipEventArgs } from '@syncfusion/ej2-popups'; 

export default {
    name: 'advanced-search',
    props: ['dataFields', 'rules'],
    data() {
        return {
			autoCompleteValueTimer: null,
			autoCompleteValues: [],
			customBooleanOperators: [
				{ key: '= Equal', value: 'Equal' },
				{ key: '!= Not Equal', value: 'NotEqual' }
			],
			customNumberOperators: [
                { key: '= Equal', value: 'Equal' },
                { key: '>= Greater Than or Equal', value: 'GreaterThanOrEqual' },
                { key: '> Greater Than', value: 'GreaterThan' },
                { key: 'Between', value: 'Between' },
                { key: '< Less Than', value: 'LessThan' },
                { key: '<= Less Than or Equal', value: 'LessThanOrEqual' },
                { key: 'Not Between', value: 'NotBetween' },
                { key: '!= Not Equal', value: 'NotEqual' },
            ],
            customStringOperators: [
                { key: 'Starts With', value: 'StartsWith' },
                { key: 'Ends With', value: 'EndsWith' },
                { key: 'Contains', value: 'Contains' },
                { key: '= Equal', value: 'Equal' },
                { key: '!= Not Equal', value: 'NotEqual' },
            ],
			tooltip: null,
			dateTemplate: () => {
				return {
					template: Vue.component('dateTemplate', {
						template: `<div>
										<ejs-datepicker 
											v-model="valuesArray[0]" 
											:change="(e) => notifyQueryBuilder(e, data, 0)">
										</ejs-datepicker>
										<ejs-datepicker 
											v-if="data.operator.includes('Between')"
											v-model="valuesArray[1]"
											:change="(e) => notifyQueryBuilder(e, data, 1)">
										</ejs-datepicker>
									</div>`,
						data() {
							return {
								valuesArray: ['', ''],
							}
						},
						methods: {
							notifyQueryBuilder(e, data, index) {
								let element = getComponent(document.getElementById('advancedSearch'), 'query-builder')

								if (data.operator === 'Between' || data.operator === 'NotBetween') {
									element.notifyChange(this.valuesArray, e.element, 'value')
								} else {
									element.notifyChange(e.value, e.element, 'value')
								}	
							},
						},
						created() {
							if (this.data.operator.includes('Between')) {
								this.valuesArray = this.data.rule.value;
							} else {
								this.valuesArray = [this.data.rule.value];
							}
						}
					})
				}
			},
			booleanTemplate: () => {
				return {
					template: Vue.component('booleanTemplate', {
						template: `<ejs-dropdownlist 
										v-model="data.rule.value"
										:dataSource="booleanFields"
										:fields="fields"
										:change="notifyQueryBuilder">
									</ejs-dropdownlist>`,
						data() {
							return {
								booleanFields: [{ Text: 'Yes', Value: 'true'}, { Text: 'No', Value: 'false' }],
								fields: this.$store.state.DropdownFields,
							}
						},
						methods: {
							notifyQueryBuilder(e) {
								let element = getComponent(document.getElementById('advancedSearch'), 'query-builder')
								element.notifyChange(e.value, e.element, 'value');
							},
						}
					})
				}
			},
			queryTemplate: () => {
                return {
                    template: Vue.component('queryTemplate', {
                        template: `
									<div>
										<ejs-combobox 
											:dataSource="autoCompleteValues" 
											v-model="valuesArray[0]"
											:allowFiltering="true"
											:fields="fields"
											:open="onOpenDropdown"
											:change="(e) => notifyQueryBuilder(e, data, 0)"
											popupWidth="300px"
											:filtering="(e) => getAutoCompleteValues(e, data.field)">
										</ejs-combobox>
										<ejs-combobox 
											v-if="data.operator.includes('Between')"
											v-model="valuesArray[1]"
											:dataSource="autoCompleteValues" 
											:allowFiltering="true"
											:fields="fields"
											:open="onOpenDropdown"
											:change="(e) => notifyQueryBuilder(e, data, 1)"
											popupWidth="300px"
											:filtering="(e) => getAutoCompleteValues(e, data.field)">
										</ejs-combobox>
									</div>
									`,
						data() {
							return {
								autoCompleteValueTimer: null,
								autoCompleteValues: [],
								fields: this.$store.state.DropdownFields,
								valuesArray: [],
							}
						},
						methods: {
							notifyQueryBuilder(e, data, index) {
								let element = getComponent(document.getElementById('advancedSearch'), 'query-builder')

								if (data.operator === 'Between' || data.operator === 'NotBetween') {
									element.notifyChange(this.valuesArray, e.element, 'value')
								} else {
									if (e.itemData) {
										element.notifyChange(e.itemData.Value, e.element, 'value')
									} else {
										element.notifyChange('', e.element, 'value')
									}
								}								
							},
							getAutoCompleteValues(e, fieldName) {
								if (this.autoCompleteValueTimer) {
									clearTimeout(this.autoCompleteValueTimer);
								}

								if (e.text) {
									setTimeout(() => {
										let noData = document.querySelector('.e-nodata');
										noData.textContent = 'Searching...'
									}, 100)

									this.autoCompleteValueTimer = setTimeout(() => {
										let dto = {
											FieldName: fieldName,
											UserInput: e.text,
											TemplateName: this.$store.state.SessionsModule.SelectedTemplate,
										};

										this.$store.dispatch('SessionsModule/getAutoCompleteValue', dto)
											.then((response) => {
												this.autoCompleteValues = response;
											});
									}, 1000);

								}
        					},
							onOpenDropdown(args) {
								args.popup.collision = { X: '0px', Y: 'bottom' };
            					args.popup.position = { X: '0px', Y: 'bottom' };
							},
						},
						created() {
							if (this.data.operator.includes('Between')) {
								this.valuesArray = this.data.rule.value;
							} else {
								this.valuesArray = [this.data.rule.value];
							}
						}
                    }),
                };
            },
		}
           
    },
	watch: {
		advancedSearchRules: {
			deep: true,
            handler() {	
				//We have to refresh before and after setting the rules or Syncfusion throws errors
				this.$refs.advancedSearch.ej2Instances.refresh();
				this.$refs.advancedSearch.setRules(this.advancedSearchRules);
				this.$refs.advancedSearch.ej2Instances.refresh();
            },
		}
	},
	computed: {
		advancedSearchFields() {
			return this.dataFields;
		},
		advancedSearchRules() {
			return this.$store.state.SessionsModule.AdvancedSearchRules;
		},
	},
    methods: {
        getAdvancedSearchFieldType(field) {
			if (field.InputDataType === 'Date') {
				return 'date'
			}

			if (field.InputDataType === 'Boolean' || field.InputType === 'Checkbox') {
				return 'boolean'
			}

			if (field.InputDataType === 'Numeric' || field.InputDataType === 'Decimal') {
				return 'number'
			}

			return 'string';
		},
		getAdvancedSearchOperators(field) {
			if (field.InputDataType === 'Date' || field.InputDataType === 'Numeric' || field.InputDataType === 'Decimal') {
				return this.customNumberOperators;
			}

			if (field.InputDataType === 'Boolean' || field.InputType === 'Checkbox') {
				return this.customBooleanOperators;
			}

			return this.customStringOperators;
		},
		getAdvancedSearchTemplate(field) {
			if (field.InputDataType === 'Date') {
				return this.dateTemplate;
			}

			if (field.InputDataType === 'Boolean' || field.InputType === 'Checkbox') {
				return this.booleanTemplate;
			}

			return this.queryTemplate;
		},
		onBeforeRenderTooltip(args) { 
			// If the list item is not in the Advanced Search dropdown or the text is less than 20 characters, do not show a tooltip
			if (!args.target.parentElement.id.includes('advancedSearch') || args.target.innerText.length < 20) {
				args.cancel = true;
				return;
			} else {
				this.tooltip.content = args.target.innerText; 
				this.tooltip.dataBind(); 
			}
		},
		closeTooltip() {
			this.tooltip.close();
		}
    },
	created() {
		this.tooltip = new Tooltip({ 
			target: '.e-list-item', 
			position: "TopCenter", 
			beforeRender: this.onBeforeRenderTooltip.bind(this) 
		}); 
		this.tooltip.appendTo('body'); 
	}
};
</script>
