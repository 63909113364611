/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import DMUtils from '../dmFramework/dmJS/dmUtils.js';

import store from './js/store.js';
import signInPage from './views/authorization/signInPage/signInPage.vue';
import headersPage from './views/headers/headersPage/headersPage.vue';
import observationsPage from './views/observations/observationsPage/observationsPage.vue';
import sessionManagementPage from './views/sessionManagement/sessionManagementPage/sessionManagementPage.vue';
import userProfilePage from './views/userManagement/userProfile.vue';
import usersPage from './views/userManagement/usersPage.vue';

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: window.path + '/sign-in/:id?',
            name: 'signin',
            component: signInPage,
        },
        {
            path: window.path + '/headers/:id?',
            name: 'headers',
            component: headersPage,
        },
        {
            path: window.path + '/observations/:id?',
            name: 'observations',
            component: observationsPage,
        },
        {
            path: window.path + '/session-management',
            name: 'sessionManagement',
            component: sessionManagementPage,
        },
        {
            path: window.path + '/user-profile',
            name: 'userProfile',
            component: userProfilePage,
        },
        {
            path: window.path + '/users',
            name: 'users',
            component: usersPage,
        },
        {
            path: window.path + '/:assetID?',
            name: 'signin',
            component: signInPage,
        },
    ],
});

router.beforeEach((to, from, next) => {
	store.commit('setIsSidebarOpen', false);

	store.state.ModalModule.ModalsOpened.forEach((modal) => {
		store.commit('ModalModule/closeModal', modal.modalID)
	})
	
	if (window.event && window.event.type === 'popstate') {
		if (store.state.UseNavigationWarning) {

			//Set short delay to make sure new warning modal can be added to the DOM in the event the user clicks back button mutliple times

			setTimeout(() => {
				warnAboutUnsavedChanges(to, next);
			}, 100)
			
			return;
		}
	}
    if (to.name !== 'signin') {
        if (store.state.AuthorizationModule.SignedInUser.UserID) {
			saveRoute(to);
			next();
        } else {
            saveRoute(to);
            router.push({ name: 'signin' });
            next();
        }
    } else {
        next();
    }
});

function saveRoute(to) {
    localStorage.setItem('posm-page', to.name);
    localStorage.setItem('posm-query', JSON.stringify(to.query));
}

function warnAboutUnsavedChanges(to, next) {
	let role = store.state.AuthorizationModule.SignedInUser.Role;

	if (role === 'Admin' || role === 'User') {
		let message = 'You have unsaved changes. Are you sure you want to leave?';

		if (store.state.SessionsModule.IsEditModeEnabled) {
			message = 'You are still in Edit Mode. Are you sure you want to leave?';
		}

		let confirmCallback = function () {
			store.commit('setUseNavigationWarning', false);
			store.commit('SessionsModule/setIsEditModeEnabled', false);
			saveRoute(to);
			next();
		};
	
		let denyCallback = function () {
			next(false);
		};
	
		DMUtils.confirmShow(message, 'Warning', confirmCallback, denyCallback, 'Yes', 'No');
		return '';
	} else {
		next();
	}
}

Vue.use(Router);

export default router;
