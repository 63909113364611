const navigationModule = {
    namespaced: true,
    state: () => ({
        CurrentTemplate: '',
    }),
    mutations: {
        setActiveTemplate(state, templateName) {
            state.CurrentTemplate = templateName;
        },
    },
    getters: {
        ActiveTemplate(state) {
            return state.CurrentTemplate;
        },
    },
};

export default navigationModule;
