import endpoints from '../../js/endpoints';
import sessionsEndpoints from './sessions.endpoints';

const sessionsModule = {
    namespaced: true,
    state: () => ({
        AdvancedSearchDataFields: [],
		AdvancedSearchRules: {
			condition: 'and',
			rules: []
		}, 
        Directions: [],
		IsEditModeEnabled: false,
		NavigatedToHeadersFrom: '',
		SavedSearches: [],
		SelectedTemplate: '',
		SelectedSessionIndex: -1,
        Session: null,
        Sessions: [],
		UserColumns: [],
    }),
    mutations: {
        clearStore(state) {
            state.AdvancedSearchDataFields = [];
			state.AllColumns = [],
            state.Directions = [];
			state.Mode = '',
			state.SavedSearches = [];
			state.SelectedTemplate = '',
            state.Session = null;
            state.Sessions = [];
			state.UserColumns = [],
            state.ValidationError = null;
        },
		setIsEditModeEnabled(state, bool) {
			state.IsEditModeEnabled = bool;
		},
		setNavigatedToHeadersFrom(state, page) {
			state.NavigatedToHeadersFrom = page;
		},
		setUserColumns(state, columns) {
			state.UserColumns = columns;
		},
		setSavedSearches(state, searches) {
			state.SavedSearches = searches;
		},
        setSession(state, session) {
            state.Session = session;
        },
        setSessions(state, sessions) {
            state.Sessions = sessions;
        },
        setDataFields(state, fields) {
            state.AdvancedSearchDataFields = fields;
        },
        setDirections(state, directions) {
            state.Directions = directions;
        },
        setQuickFill(state, quickFill) {
			for (let key in quickFill.CoordinateInformation) {
				if (key !== 'PosmUniqueCode') {
					state.Session.CoordinateInformation[key] = quickFill.CoordinateInformation[key]
				}
			}

            state.Session.SessionPages = quickFill.SessionPages;
        },
		setAdvancedSearchRules(state, rules) {
			state.AdvancedSearchRules = rules;
		},
		setSelectedTemplate(state, template) {
			state.SelectedTemplate = template;
		},
		setSelectedSessionIndex(state, id) {
			state.SelectedSessionIndex = id;
		}
    },
    actions: {
        clearStore({ commit }) {
            commit('clearStore');
        },
        createSession({ dispatch }, session) {
            return sessionsEndpoints.createSession(session)
				.then((response) => {
					return dispatch('getSessionByID', response.SessionID);
				});
        },
		deleteSavedSearch({dispatch}, dto) {
			return sessionsEndpoints.deleteSavedSearch(dto)
				.then(() => {
					return dispatch('getSavedSearches');
				})
		},
        getAccuracyOfGpsValues({}, template) {
            return sessionsEndpoints.getAccuracyOfGpsValues(template)
				.then((response) => {
					return response;
				});
        },
		getAutoCompleteValue({}, dto) {
			return sessionsEndpoints.getAutoCompleteValue(dto)
				.then((response) => {
					return response;
				})
		},
		getUserColumns({commit}) {
			return sessionsEndpoints.getUserColumns()
				.then((response) => {
					commit('setUserColumns', response);
				})
		},
        getCoordinateSystemValues({}, template) {
            return sessionsEndpoints.getCoordinateSystemValues(template)
				.then((response) => {
					return response;
				});
        },
        getFieldsForSearch({ commit }, template) {
            return sessionsEndpoints.getFieldsForSearch(template)
				.then((response) => {
					commit('setDataFields', response);
				});
        },
        getDirections({ commit }, template) {
            return sessionsEndpoints.getDirections(template)
				.then((response) => {
					commit('setDirections', response);
				});
        },
		getSessionByAssetID( {}, assetID) {
			return sessionsEndpoints.getSessionByAssetID(assetID)
				.then((response) => {
					return response;
				})
		},
        getNewSession({ commit }, template) {
            return sessionsEndpoints.getNewSession(template)
				.then((response) => {
					commit('setSession', response);
				});
        },
        getQuickFill({ commit }, template) {
            return sessionsEndpoints.getQuickFill(template)
				.then((response) => {
					commit('setQuickFill', response);
				});
        },
		getSavedSearches({ commit }) {
			return sessionsEndpoints.getSavedSearches()
				.then((response) => {
					commit('setSavedSearches', response);
				})
		},
        getSessionByID({ commit }, sessionID) {
            return sessionsEndpoints.getSessionByID(sessionID)
				.then((response) => {
					commit('setSession', response);
					return response;
				});
        },
        getSessionsForSearch({ commit }, searchTerms) {
            return sessionsEndpoints.searchSessions(searchTerms)
				.then((response) => {
					response.Rows.forEach((session) => {
						if (session.SessionSize && session.SessionSize > 0) {
							if (session.SessionSize < 1024) {
								session.SessionSize = session.SessionSize + ' KB';
							} else if (session.SessionSize > 1024 && session.SessionSize < 1048576) {
								session.SessionSize = (session.SessionSize / 1024).toFixed(1) + ' MB';
							} else {
								session.SessionSize = (session.SessionSize / 1048576).toFixed(1) + ' GB';
							}
						}
					})

					let sessions = [];
					sessions.result = [...response.Rows];
					sessions.count = response.RowCount;

					commit('setSessions', sessions);
				});
        },
        getVerticalDatumValues({}, template) {
            return sessionsEndpoints.getVerticalDatumValues(template)
				.then((response) => {
					return response;
				});
        },
		updateSavedSearch({dispatch}, dto) {
			return sessionsEndpoints.updateSavedSearch(dto)
				.then(() => {
					return dispatch('getSavedSearches');
				})
		},
        updateSession({ dispatch }, session) {
            return sessionsEndpoints.updateSession(session)
				.then(() => {
					return dispatch('getSessionByID', session.SessionID);
				});
        },
		updateUserColumns({}, columns) {
			return sessionsEndpoints.updateUserColumns(columns)
				.then((response) => {
					return response;
				})
		}
    },
};

export default sessionsModule;
