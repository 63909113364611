export default {
    getAllObservations(sessionID) {
        return DMUtils.http({
            method: 'GET',
            params: { SessionID: sessionID },
            url: '/Observations/GetAll',
        }).then((response) => {
            return response;
        });
    },
    getFaultCodesForTemplate(templateName, category, observationID) {
        let queryStringParams = { TemplateName: templateName };

        if (category != null && category != '') {
            queryStringParams.FaultCodeCategoryName = category;
        }

        if (observationID != null) {
            queryStringParams.ObservationID = observationID;
        }

        return DMUtils.http({
            method: 'GET',
            params: queryStringParams,
            url: '/Observations/GetFaultCodes',
        }).then((response) => {
            return response;
        });
    },
    getAllObservationCategoryOptions(templateName) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: templateName },
            url: '/Observations/GetFaultCodeCategories',
        }).then((response) => {
            return response;
        });
    },
    getObservationByID(observationID) {
        return DMUtils.http({
            method: 'GET',
            params: { ObservationID: observationID },
            url: '/Observations/GetByID'
        }).then((response) => {

            // MUSTFIX: Checkbox hookup temp fix
            response.Fields.forEach((field) => {
                if (field.InputType === 'Checkbox') {
                    if (field.Value === 'True') {
                        field.Value = true;
                    }
                    else {
                        field.Value = false;
                    }
                }
            });

            return response;
        });
    },
    getNextContinuousValue(sessionID) {
        let config = {
            method: 'GET',
            url: '/Observations/GetNextContinuousDefectCode',
            params: { SessionID: sessionID },
        };
        let hideSpinner = true;

        return DMUtils.http(config, hideSpinner)
            .then((response) => {
                return response;
            });
    },
    getObservationInputs(faultCode, templateName) {
        return DMUtils.http({
            method: 'GET',
            params: { FaultCodeID: faultCode },
            url: '/Observations/GetFieldsByFaultCode',
        }).then((response) => {
            return response;
        });
    },
    createObservation(dto) {
        return DMUtils.http({
            method: 'POST',
            url: '/Observations/Create',
            data: dto,
        }).then((response) => {
            return response;
        });
    },
    editObservation(dto) {
        return DMUtils.http({
            method: 'POST',
            url: '/Observations/Update',
            data: dto,
        }).then((response) => {
            return response;
        });
    },
    deleteObservation(observation) {
        let dto = { ObservationID: observation.ObservationID, LastModifiedDate: observation.LastModifiedDate };

        return DMUtils.http({
            method: 'POST',
            url: '/Observations/Delete',
            data: dto,
        }).then((response) => {
            return response;
        });
    },
    beginEditMode(sessionID) {
        return DMUtils.http({
            method: 'GET',
            params: { SessionID: sessionID },
            url: '/Sessions/BeginEditMode',
        }).then((response) => {
            return response;
        });
    },
    endEditMode(dto) {
        return DMUtils.http({
            method: 'POST',
            data: dto,
            url: '/Sessions/EndEditMode',
        }).then((response) => {
            return response;
        });
    },

    // Images and Videos

    addImageToObservation(dto) {
        return DMUtils.http({
            method: 'POST',
            url: '/Observations/AddImage',
            data: dto
        }).then((response) => {
            return response;
        });
    },
    editImageForObservation(dto) {
        return DMUtils.http({
            method: 'POST',
            url: '/Observations/EditImage',
            data: dto,
        }).then((response) => {
            return response;
        });
    },
    deleteImageForObservation(dto) {
        return DMUtils.http({
            method: 'POST',
            url: '/Observations/DeleteImage',
            data: dto,
        }).then((response) => {
            return response;
        });
    },
    addVideoToSession(data, sessionID) {
        return DMUtils.http({
            method: 'POST',
            params: { SessionID: sessionID },
			data: data,
            url: '/Sessions/AddVideo'
        }).then((response) => {
            return response;
        });
    },
    deleteVideoForSession(videoID) {
        return DMUtils.http({
            method: 'POST',
            data: {VideoID: videoID},
            url: '/Sessions/DeleteVideo'
        }).then((response) => {
            return response;
        });
    },

    // Pipe related

    getPipeNodes(sessionID) {
        return DMUtils.http({
            method: 'GET',
            params: { SessionID: sessionID },
            url: '/Sessions/GetPipe',
        }).then((response) => {
            return response;
        });
    },

    // Hot buttons

    getHotbuttons(templateName) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: templateName },
            url: '/Hotbuttons/GetAll',
        }).then((response) => {
            return response;
        });
    },
    setHotbutton(dto) {
        return DMUtils.http({
            method: 'POST',
            data: dto,
            url: '/Hotbuttons/Update',
        }).then((response) => {
            return response;
        });
    },
    deleteHotbutton(dto) {
        return DMUtils.http({
            method: 'POST',
            data: dto,
            url: '/Hotbuttons/Delete',
        }).then((response) => {
            return response;
        });
    },
}