<template src="./addImageModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'add-image-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {
                Name: '',
                Remarks: '',
                ImageSrc: '',
            },
			lastUpdatedImage: null,
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
          return this.mode == 'Add' ? 'Add Image': 'Edit Image';
        },
    },
    methods: {
        fileUploaded(e) {
            let uploadedFile = e.target.files[0];

            let fileExtension = uploadedFile.type.replace(/(.*)\//g, '');

            this.dto.rawUploadedFile = uploadedFile;
            this.dto.ImageFileName = uploadedFile.name;

            var reader = new FileReader();

            let that = this;
            reader.onload = function (e) {
                that.dto.ImageContentBase64 = reader.result;
            };

            reader.readAsDataURL(uploadedFile);
        },
        btnSaveOnClick() {
			let dispatch = 'addImage';

			if (this.mode === 'Edit') {
				dispatch = 'editImage';
			}

			this.$store.dispatch('ObservationModule/' + dispatch, this.dto)
				.then((response) => {
					this.lastUpdatedImage = response;
					return this.$store.dispatch('ObservationModule/getObservationById', this.dto.ObservationID);
				})
				.then(() => {
					this.successClose(this.lastUpdatedImage);
				})
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.dto = DMUtils.copy(this.modalData.dto);
    },
}
</script>