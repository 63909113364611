export default {
	// Headers
	createSession(session) {
        return DMUtils.http({
            method: 'POST',
            data: session,
            url: '/Sessions/Create',
        }).then((response) => {
            return response;
		})
    },
	getNewSession(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/GetNew',
        }).then((response) => {
            return response;
        });
    },
    getSessionByID(sessionID) {
        return DMUtils.http({
            method: 'GET',
            params: { SessionID: sessionID },
            url: '/Sessions/GetByID',
        }).then((response) => {
            return response;
        });
    },
	getQuickFill(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/getQuickFill',
        }).then((response) => {
            return response;
        });
    },
    getAccuracyOfGpsValues(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/GetAccuracyOfGpsValues',
        }).then((response) => {
            return response;
        });
    },
    getCoordinateSystemValues(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/GetCoordinateSystemValues',
        }).then((response) => {
            return response;
        });
    },
    getVerticalDatumValues(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/GetVerticalDatumValues',
        }).then((response) => {
            return response;
        });
    },
	updateSession(session) {
        return DMUtils.http({
            method: 'POST',
            data: session,
            url: '/Sessions/Update',
        }).then((response) => {
            return response;
		});
    },
	getSessionByAssetID(assetID) {
		return DMUtils.http({
			method: 'GET',
			params: { AssetID: assetID },
			url: '/Sessions/GetSessionByAssetID'
		}).then((response) => {
			return response;
		})
	},
	//Sessions Grid Columns
	getUserColumns() {
		return DMUtils.http({
			method: 'GET',
			url: '/Sessions/GetUserColumns'
		}).then((response) => {
			return response;
		})
	},
	updateUserColumns(dto) {
		return DMUtils.http({
			method: 'POST',
			url: '/Sessions/UpdateUserColumns',
			data: { columns: dto }
		}, true)
			.then((response) => {
				return response;
			})
	},
	//Session Search
	getAutoCompleteValue(dto) {
		return DMUtils.http({
			method: 'GET',
			params: { FieldName: dto.FieldName, UserInput: dto.UserInput, TemplateName: dto.TemplateName},
			url: '/Sessions/GetAutoCompleteFieldValues'
		}, true)
			.then((response) => {
				return response;
			})
	},
	getDirections(template) {
        return DMUtils.http({
            method: 'GET',
            params: { TemplateName: template },
            url: '/Sessions/GetDirectionFieldValues',
        }).then((response) => {
            return response;
        });
    },
	getFieldsForSearch(template) {
		return DMUtils.http({
			method: 'GET',
			params: { TemplateName: template },
			url: '/Templates/GetFieldsForSearch'
		}).then((response) => {
			return response;
		})
	},
	getTemplates(includeAny) {
        return DMUtils.http({
            method: 'GET',
            params: { IncludeAny: includeAny },
            url: '/Templates/GetAll',
        }).then((response) => {
            return response;
        });
    },
	searchSessions(dto) {
		let searchTerms = {
			StartDate: '',
			EndDate: '',
			TemplateName: dto.TemplateName || '',
			ProjectName: dto.ProjectName || '',
			StartID: dto.StartID || '',
			EndID: dto.EndID || '',
			AssetID: dto.AssetID || '',
			FlowDirection: dto.FlowDirection || '',
			AssetLocation: dto.AssetLocation || '',
			PageNumber: dto.PageNumber || 1,
			NumberOfRecordsPerPage: dto.NumberOfRecordsPerPage || 100,
			SortByColumn: dto.SortByColumn || 'Date',
			SortDirection: dto.SortDirection || 'Descending',
			AdvancedSearch: dto.AdvancedSearch || '',
		};

		if (dto.Date && dto.Date.length) {
			searchTerms.StartDate = dto.Date[0];
			searchTerms.EndDate = dto.Date[1];
		}

        return DMUtils.http({
            method: 'POST',
            data: searchTerms,
            url: '/Sessions/GetAll',
        }).then((response) => {
            return response;
        });
    },
	//Saved Searches
	deleteSavedSearch(dto) {
		return DMUtils.http({
			method: 'POST',
			data: dto,
			url: '/Sessions/DeleteUserSearch'
		}).then((response) => {
			return response;
		})
	},
	getSavedSearches() {
		return DMUtils.http({
			method: 'GET',
			url: '/Sessions/GetUserSearches'
		}).then((response) => {
			return response
		})
	},
	updateSavedSearch(dto) {
		return DMUtils.http({
			method: 'POST',
			data: dto,
			url: '/Sessions/UpdateUserSearch'
		}).then((response) => {
			return response;
		})
	},   
};
