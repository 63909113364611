<template src="./usersPage.html"></template>

<script>
import Vue from 'vue';

export default {
    name: 'users-page',
    data() {
        return {
            selectedRow: {},
			selectedUser: null,
			usersTableSortBy: {
				columns: [{ field: 'Username', direction: 'Ascending'}]
			},
			usersTableActions: [
				{
					text: 'Edit User'
				},
				{
					text: 'Edit Password'
				},
				{
					text: 'Delete User'
				}
			],
            usersTableColumns: [],
			isActiveTemplate: function () {
                let self = this;
                return {
                    template: Vue.component('columnTemplate', {
                        template: `<i class="fa fa-check" v-if="data.IsActive"></i>
									<i class="fa fa-times" v-else></i>`,
                        data() {
                            return {
                                data: self,
                            };
                        },
                    }),
                };
            },
        };
    },
    computed: {
		users() {
			return this.$store.state.UsersModule.Users;
		}
    },
    watch: {},
    methods: {
        btnAddUserOnClick() {
            let modalName = 'usersEditModal';
			let modalID = modalName + DMUtils.getRandomGuid();
			let modalData = { dto: {}, mode: 'Add' };
		
			let modalCreateDto = { modalName, modalID, modalData };
			this.$store.commit('ModalModule/openModal', modalCreateDto);
        },
        btnEditUserOnClick(args) {
            let selectedRow = DMUtils.copy(args.target.__vue__.data);

			this.$store.dispatch('UsersModule/getUserByID', selectedRow.UserID)
			.then(() => {
				let modalName = 'usersEditModal';
            	let modalID = modalName + DMUtils.getRandomGuid();
            	let modalData = { dto: this.$store.state.UsersModule.CurrentUser, mode: 'Edit' };

            	let modalCreateDto = { modalName, modalID, modalData };
            	this.$store.commit('ModalModule/openModal', modalCreateDto);
			})
        },
		deleteUser() {
			this.$store.dispatch('UsersModule/getUserByID', this.selectedUser.rowData.UserID)
				.then(() => {
					this.$store.dispatch('UsersModule/deleteUser', this.$store.state.UsersModule.CurrentUser);
				})
		},
		usersTableActionOnClick(args) {
			if (args.item.text === 'Delete User') {
				const message = 'Are you sure you want to delete this user?';
                    const title = 'Confirm';
                    const confirmCallback = this.deleteUser;

                    DMUtils.openApplicationModal('dmConfirmModal', {
                        title: title,
                        message: message,
                        confirmCallback: confirmCallback,
                        confirmText: 'Yes',
                        denyText: 'No',
                    });
			} else {
				this.$store.dispatch('UsersModule/getUserByID', this.selectedUser.rowData.UserID)
					.then(() => {
						let modalName = 'usersEditModal';
						let modalID = modalName + DMUtils.getRandomGuid();
						let modalData = { dto: this.$store.state.UsersModule.CurrentUser, mode: args.item.text };
		
						let modalCreateDto = { modalName, modalID, modalData };
						this.$store.commit('ModalModule/openModal', modalCreateDto);
					})
			}
		},
		usersTableRowOnClick(args) {
			this.selectedUser = args;
		},
    },
    created() {
		this.$store.commit('setUseNavigationWarning', false);
		this.$store.commit('SessionsModule/setIsEditModeEnabled', false);
		
		this.$store.dispatch('UsersModule/clearStore')
			.then(() => {
				this.$store.dispatch('UsersModule/getAll');
			})

        this.usersTableColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
				ellipsisActions: this.usersTableActions,
                iconOnClick: this.usersTableActionOnClick,
                width: '55px',
				textAlign: 'Center'
            },
            {
                key: 'Username',
                label: 'Username',
                textAlign: 'Left',
            },
            {
                key: 'Role',
                label: 'Role',
                textAlign: 'Left',
            },
            {
                key: 'IsActive',
                label: 'Active',
                textAlign: 'Left',
				template: this.isActiveTemplate,
            },
        ];
    },
};
</script>
