<template src="./rightButtonMenu.html"></template>

<script>
export default {
    name: 'right-button-menu',
    props: ['buttons'],
    data() {
        return {
           menuIsOpen: false,
        }
    },
    methods: {
	   toggleRightButtonMenu() {
			this.menuIsOpen = !this.menuIsOpen;
		},
		getButtonClasses(button) {
			let classes = 'dm-flex-row dm-align-items-center dm-justify-content-space-between posm-right-menu-button dm-color-white dm-margin-bottom-sm ';

			classes += button.color;

			if (this.menuIsOpen) {
				classes += ' expanded';
			}

			return classes;
		}
    },
};
</script>
