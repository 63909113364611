export default {
    // #region Authorization

    signIn(user) {
        let dto = {
            Username: user.Username,
            Password: user.Password,
        };

        if (!dto.Username) {
            dto.Username = '';
        }

        if (!dto.Password) {
            dto.Password = '';
        }
        return DMUtils.http({
            method: 'POST',
            url: '/Auth/SignIn',
            data: dto,
        }).then((response) => {
            DMUtils.setAuthToken(response.AuthToken);
            return response;
        });
    },
    signInWithAuthToken(authToken) {
        DMUtils.setAuthToken(authToken);

        return DMUtils.http({
            method: 'POST',
            url: '/Auth/SignInWithAuthToken',
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                DMUtils.clearAuthToken();

                return Promise.reject(error);
            });
    },
    signOut() {
        return DMUtils.http({
            method: 'POST',
            url: '/Auth/SignOut',
        }).then((response) => {
            DMUtils.clearAuthToken();
            return response;
        });
    }, 
	   
	//#endregion Authorization

    // #region User Management

    getAllUsers() {
        return DMUtils.http({
            method: 'GET',
            params: {},
            url: '/Users/Ping',
        }).then((response) => {
            return []; // TODO: Remove when hitting BE
            return response;
        });
    },
    addUser(user) {
        return DMUtils.http({
            method: 'GET', // TODO: Method change to POST
            params: {}, // TODO: Change to Data: user
            url: '/Users/Ping',
        }).then((response) => {
            return response;
        });
    },
    editUser(user) {
        return DMUtils.http({
            method: 'GET', // TODO: Method change to POST
            params: {}, // TODO: Change to Data: user
            url: '/Users/Ping',
        }).then((response) => {
            return response;
        });
    },
    // #endregion User Management
};
