<template src="./observationsPage.html"></template>

<script>
import Vue from 'vue';

import dropdownButton from '../../../components/DropdownButton/DropdownButton.vue';
import rightButtonMenu from '../../../components/RightButtonMenu/rightButtonMenu.vue';
import pipe from '../components/pipe/pipe.vue';

export default {
    name: 'observations-page',
    components: {
        'dropdown-button': dropdownButton,
		'right-button-menu': rightButtonMenu,
		'pipe': pipe,
    },
    data() {
        return {
			observationsTableColumns: [],
			observationsTableSortOptions: { columns: [{ field: 'Distance', direction: 'Ascending' }] },
            imageUploadTableActions: [
                {
                    text: 'Edit',
                },
                {
                    text: 'Delete',
                },
            ],
            hotKeyOptions: [
                {
                    text: 'Set Hot Key',
                    OnClick: this.setHotKey,
                },
                {
                    text: 'Delete Hot Key',
                    OnClick: this.deleteHotKey,
                },
            ],
			selectedHotkey: null,
            selectedTab: 'observations',
            selectedImageRow: null,
            isSidebarOpened: false,
            mode: 'Add',
			displayTimestamp: '',
			selectedObservationType: null,
			showInputErrors: false,
			takePicture: true,
			clockOptions: [
				{
                    Text: '',
                    Value: '',
                },
                {
                    Text: '12',
                    Value: '12',
                },
                {
                    Text: '1',
                    Value: '1',
                },
                {
                    Text: '2',
                    Value: '2',
                },
                {
                    Text: '3',
                    Value: '3',
                },
                {
                    Text: '4',
                    Value: '4',
                },
                {
                    Text: '5',
                    Value: '5',
                },
                {
                    Text: '6',
                    Value: '6',
                },
                {
                    Text: '7',
                    Value: '7',
                },
                {
                    Text: '8',
                    Value: '8',
                },
                {
                    Text: '9',
                    Value: '9',
                },
                {
                    Text: '10',
                    Value: '10',
                },
                {
                    Text: '11',
                    Value: '11',
                },
            ],
			nextContinuousValue: '',
            selectedRow: null,
            selectedVideo: '',
			videoLocationFields: { text: 'VideoName', value: 'VideoLocation' },
            dto: {
                ObservationID: -1,
                SessionID: -1,
                FaultCodeID: null,
                VideoPosition: 0,
                Distance: 0,
                Fields: [],
                //isTakePictureChecked: true,
                LastModifiedDate: '',
            },
            defaultDto: {},
            originalDto: {}, // Used to determine if changes have been made in edit mode
        };
    },
    computed: {
		displayName() {
			if (this.$store.state.ObservationModule.Session.TemplateName) {
				return this.$store.state.ObservationModule.Session.TemplateName + ' ' + this.$store.state.ObservationModule.Session.SessionDisplayName;
			}

			return '';
		},
		continuousValue() {
            if (this.dto.Fields.length > 0 && this.dto.Fields[16].Value != '') {
                return this.dto.Fields[16].Value;
            } else {
                return this.nextContinuousValue;
            }
        },
		hotbuttons() {
            return this.$store.state.ObservationModule.Hotbuttons;
        },
		inputsInSectionOne() {
			return this.dto.Fields.filter((input) => {
				return input.FieldNumber >= 1 && input.FieldNumber <= 6;
			})
        },
        inputsInSectionTwo() {
			return this.dto.Fields.filter((input) => {
				return input.FieldNumber >= 7 && input.FieldNumber <= 11;
			})
        },
        inputsInSectionThree() {
			return this.dto.Fields.filter((input) => {
				return input.FieldNumber >= 12 && input.FieldNumber <= 18;
			})
        },
        isEditModeEnabled() {
			return this.$store.state.SessionsModule.IsEditModeEnabled;
		},
		modeDisplay() {
			if (!this.isEditModeEnabled) {
				return 'View';
			}

            if (this.mode == 'Add' || this.mode == 'CompleteContinuous') {
                return 'Add';
            } 
			
			if (this.mode == 'Edit') {
                return 'Edit';
            }
        },
        observations() {
            return this.$store.state.ObservationModule.Observations;
        },
		observationCategories() {
            return this.$store.state.ObservationModule.ObservationCategoryOptions;
        },
		observationID() {
            return this.selectedRow.rowData.ObservationID;
        },
        observationImages() {
            return [...this.$store.state.ObservationModule.ObservationImages];
        },
        observationTypes() {
            return this.$store.state.ObservationModule.ObservationTypeOptions;
        },
		videoLocations() {
			return this.$store.state.ObservationModule.Session.SessionVideoLocations || [];
		},
		rightMenuButtons() {
			return [
                {
                    text: 'Add Observation',
                    icon: 'fa-plus-circle',
                    color: 'brand-color-primary',
                    onClick: () => this.toggleObservationSidebar('Add'),
					isVisible: this.isEditModeEnabled,
                },
                {
                    text: this.userIsEditUser ? 'Edit Headers' : 'View Headers',
                    icon: 'fa-list',
                    color: 'brand-color-alternate-1',
                    onClick: this.navigateToHeadersForSession,
					isVisible: true,
                },
				{
					text: this.isEditModeEnabled ? 'End Editing' : 'Begin Editing',
					icon: this.isEditModeEnabled ? 'fa-stop' : 'fa-play',
					color: this.isEditModeEnabled ? 'brand-color-alternate-2' : 'brand-color-secondary',
					onClick: this.toggleEditMode,
					isVisible: this.userIsEditUser,
				}
            ]
		},
		selectedPipeNode() {
			return this.$store.state.ObservationModule.SelectedPipeNode;
		},
		sessionID() {
            return this.$store.state.ObservationModule.Session.SessionID;
        },
		userIsEditUser() {
            return (
                this.$store.state.AuthorizationModule.SignedInUser.Role != 'Guest' &&
                this.$store.state.AuthorizationModule.SignedInUser.Role != 'View-Only'
            );
        },
    },
    watch: {
		selectedPipeNode(newVal) {
			if (newVal) {
				this.highlightAndScrollToRow(newVal);
			}
		},
         observations() {
            setTimeout(() => {
                this.highlightContinuousRowsInTable();
            }, 200);
        },
		videoLocations() {
			if (this.videoLocations.length) {
				this.selectedVideo = this.videoLocations[0];
			} else {
				this.selectedVideo = '';
			}

			this.setVideo();
		},
        selectedImageRow(image) {
            let canvas = document.getElementById('previewObservationImage');

            if (image) {
                canvas.width = 860;
                canvas.height = 400;
                var ctx = canvas.getContext('2d');
                let drawing = new Image();
                drawing.src = image.ImageUrl.replace(/^https?:/, '').replace(/3.15.106.54/, window.baseURL);
                drawing.onload = function () {
                    var hRatio = canvas.width / drawing.width;
                    var vRatio = canvas.height / drawing.height;
                    var ratio = Math.min(hRatio, vRatio);
                    var centerShift_x = (canvas.width - drawing.width * ratio) / 2;
                    var centerShift_y = (canvas.height - drawing.height * ratio) / 2;
                    ctx.drawImage(
                        drawing,
                        0,
                        0,
                        drawing.width,
                        drawing.height,
                        centerShift_x,
                        centerShift_y,
                        drawing.width * ratio,
                        drawing.height * ratio
                    );
                };
            } else {
                canvas.height = 0;
            }
        },
    },
    methods: {
        // Image / Tab Methods
        observationImagesTableRowOnClick(args) {
			this.selectedImageRow = args.rowData;
			this.$refs.imagesTable.$refs.grdTableList.ej2Instances.selectedRowIndex = args.rowIndex;
        },
        openUploadImageModal(imageDto) {
            let modalName = 'addImageModal';
            let modalID = modalName + DMUtils.getRandomGuid();
            let modalData = {
                dto: {
                    ObservationID: this.dto.ObservationID,
                    ImageFileName: '',
                    ImageContentBase64: '',
                    Remarks: '',
                },
                mode: 'Add',
                callback: this.addImageCallback,
            };

            if (imageDto != null) {
				modalData.dto.MediaID = imageDto.MediaID;
                modalData.dto.ImageFileName = imageDto.ImageFileName;
                modalData.dto.Remarks = imageDto.Remarks;
                modalData.mode = 'Edit';
            }

            let dto = { modalName, modalID, modalData };
            this.$store.commit('ModalModule/openModal', dto);
        },
        takeScreenshotFromVideo() {
            let canvas = document.createElement('canvas');

            canvas.width = observationVideo.videoWidth;
            canvas.height = observationVideo.videoHeight;

            let ctx = canvas.getContext('2d');
            ctx.drawImage(observationVideo, 0, 0, canvas.width, canvas.height);

            let base64String = canvas.toDataURL('image/jpeg');

			if (base64String === 'data:,') {
				return '';
			}
			
			return base64String;
        },
        addFromVideo() {
            let dataURI = this.takeScreenshotFromVideo();
            let timestamp = moment.duration(this.dto.VideoPosition * 1000);
            let filename = moment.utc(timestamp.asMilliseconds()).format('HH:mm:ss.000');

            let modalName = 'addImageModal';
            let modalID = modalName + DMUtils.getRandomGuid();
            let modalData = {
                dto: {
                    ObservationID: this.dto.ObservationID,
                    ImageFileName: filename,
                    ImageContentBase64: dataURI,
                    Remarks: '',
                },
                mode: 'Add',
                callback: this.addImageCallback,
            };

            let dto = { modalName, modalID, modalData };
            this.$store.commit('ModalModule/openModal', dto);
        },
        addImageCallback(lastUpdatedImage) {
            if (!lastUpdatedImage) {
                return;
            }

			let imageToSelect = null;
			let imageIndex = 0;

			this.observationImages.find((image, index) => {
				if (image.ImageFileName === lastUpdatedImage.ImageFileName) {
					imageToSelect = image;
					imageIndex = index;
				}
			})

			setTimeout(() => {
				this.selectedImageRow = imageToSelect;
				this.$refs.imagesTable.$refs.grdTableList.selectRow(imageIndex)	
			}, 100)
        },
        changeTab(tab) {
            let obsTab = document.getElementById('observationTab');
            let picTab = document.getElementById('imagesTab');

            if (tab == 'images') {
                picTab.style.display = 'inline-block';
                obsTab.style.display = 'none';
                picTab.style.height = '100%';
                obsTab.style.height = '0';
            } else {
                picTab.style.display = 'none';
                obsTab.style.display = 'inline-block';
                picTab.style.height = '0';
                obsTab.style.height = '100%';
            }

            this.selectedTab = tab;
        },
        imageUploadTableActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.openUploadImageModal(this.selectedImageRow);
            } else if (args.item.text === 'Delete') {
                const message = 'Are you sure you want to delete this image?';
                const title = 'Confirm';
                const confirmCallback = this.deleteObservationImage;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            }
        },
        deleteObservationImage() {
            let dto = {
                ObservationID: this.selectedImageRow.ObservationID,
                MediaID: this.selectedImageRow.MediaID,
                ImageUrl: this.selectedImageRow.ImageUrl,
            };

            this.$store.dispatch('ObservationModule/deleteImage', dto)
				.then(() => {
					this.selectedImageRow = null;
					this.$refs.imagesTable.$refs.grdTableList.ej2Instances.selectedRowIndex = -1;
				});
        },
        // Hotkey Methods
        hotkeyOnSelect(args, hotbutton) {
            if (args.item.text == 'Set Hot Key') {
                this.setHotKey(hotbutton);
            } else if (args.item.text == 'Delete Hot Key') {
                this.deleteHotKey(hotbutton.HotButtonID);
            }
        },
        setHotKey(hotbutton) {
            if (this.dto == undefined || this.dto.FaultCodeID < 1) {
                let message = 'Please select an observation to set a hot key.';
                let title = 'Warning';
                DMUtils.alertShow(message, title);
                return;
            }

            let hotbutonSelected = this.hotbuttons.find((x) => x.HotButtonNumber == hotbutton.HotButtonNumber);
            let hotbuttonDto = DMUtils.copy(hotbutonSelected);
            hotbuttonDto.Fields = this.dto.Fields;
            hotbuttonDto.FaultCodeID = this.dto.FaultCodeID;

            this.$store.dispatch('ObservationModule/setHotbutton', hotbuttonDto);
        },
        deleteHotKey(hotbuttonID) {
            let hotbutonSelected = this.hotbuttons.find((x) => x.HotButtonID == hotbuttonID);

            this.$store.dispatch('ObservationModule/deleteHotbutton', hotbutonSelected);
        },
        useHotKey(hotbutton) {
			this.selectedHotkey = hotbutton.HotButtonNumber;
			this.dto.Fields = [];			
            this.dto.Distance = 0;
			this.dto.ObservationCategory = null;

			//Allow time for inputs to be destroyed before re-rendering
			setTimeout(() => {
				if (hotbutton.FaultCodeID === -1) {
					this.dto.FaultCodeID = null;
				} else {
					this.dto.FaultCodeID = hotbutton.FaultCodeID;
					this.dto.Fields = hotbutton.Fields;
	
					this.dto.Fields.forEach((field) => {
						if (field.Value === '' && field.InputType !== 'Checkbox') {
							field.Value = null;
						}
					})
				}
			}, 100)

        },
		getHotkeyClasses(key) {
			let classes = 'dm-color-white border-none app-btn-md hotkey';

			if (this.selectedHotkey === key.HotButtonNumber) {
				classes += ' hotkey-selected';
			}

			return classes;
		},

        // Observation Methods

        manageVideos() {
            let modalName = 'manageVideosModal';
            let modalID = modalName + DMUtils.getRandomGuid();
            let modalData = {
				activeVideo: this.selectedVideo
			};

            let dto = { modalName, modalID, modalData };
            this.$store.commit('ModalModule/openModal', dto);
        },
		//TODO: clean this up
        toggleEditMode() {
            let editModeCleanup = () => {
				this.$store.commit('SessionsModule/setIsEditModeEnabled', !this.isEditModeEnabled);
				this.$refs.observationsTable.$refs.grdTableList.refresh();
            };

            if (!this.isEditModeEnabled) {
                this.$store.dispatch('ObservationModule/beginEditMode', this.sessionID)
					.then(() => {
						this.$store.commit('setUseNavigationWarning', true);
						editModeCleanup();
					});
            } else {
                let endEditMode = (isCompleted) => {
                    let dto = { 
							SessionID: this.sessionID, 
							IsCompleted: isCompleted, 
							LastModifiedDate: this.$store.state.ObservationModule.Session.LastModifiedDate, CoordinateInformation: this.$store.state.ObservationModule.Session.CoordinateInformation, 
							TemplateName: this.$store.state.ObservationModule.Session.TemplateName,
						};

                    this.$store.dispatch('ObservationModule/endEditMode', dto)
						.then(() => {
							this.$store.commit('setUseNavigationWarning', false);
							editModeCleanup();
							return this.$store.dispatch('SessionsModule/getSessionByID', this.sessionID)
						})
						.then((response) => {
							this.$store.commit('ObservationModule/setSession', response);
						})
                };

                let message = 'Do you want to mark this session as complete?';
                let title = 'Confirm';
                let confirmCallback = () => {
                    endEditMode(true);
                };
                let denyCallback = () => {
                    endEditMode(false);
                };
                let confirmText = 'Yes';
                let denyText = 'No';

                DMUtils.confirmShow(message, title, confirmCallback, denyCallback, confirmText, denyText);
            }
        },
        getClassesForInput(item) {
            if (item === null) {
                return;
            }

            let cssClasses = 'posm-observation-input';

			if (item.IsVisible && item.IsRequired) {
                cssClasses += ' required';
            }

            return cssClasses;
        },
        getRequiredOutlineClasses(item) {
            if (item === null) {
                return;
            }

            if (item.IsRequired && this.showInputErrors) {
                if (item.InputType == 'Checkbox') {
                    return 'required-outline-checkbox';
                } else {
                    return 'required-outline';
                }
            }
        },
        isInputEnabled(field) {
            if (this.isEditModeEnabled === false) {
                return false;
            } else if (field !== null) {
                return field.IsVisible;
            } else {
                return true;
            }
        },
        observationsTableRowOnClick(args) {
            this.selectedRow = args;
        },
        getAddEditObservationDropdownOptions() {
            let args = { Category: this.dto.ObservationCategory };
            if (this.mode === 'Edit') {
                args.ObservationID = this.selectedRow.rowData.ObservationID;
            }

            this.$store.dispatch('ObservationModule/getAllObservationTypeOptions', args)
				.then(() => {
					if (this.dto.ObservationCategory) {
						this.selectedObservationType = this.observationTypes[0].Value;
						this.dto.FaultCodeID = this.observationTypes[0].Value;

						this.changeObservationType(this.selectedObservationType)
					}
				});
        },
        observationTypeOnChange(args) {
			if (args.isInteracted) {
				if (args.itemData) {
					this.changeObservationType(args.itemData.Value);
				} else {
					this.dto.Fields = [];
				}
			}
        },
		changeObservationType(type) {
			this.dto.Fields = [];
            
			this.$store.dispatch('ObservationModule/getObservationInputs', type)
				.then((response) => {
					this.showInputErrors = false;
					this.dto.Fields = response;
					this.selectedObservationType = type;
				});
		},
        highlightContinuousRowsInTable() {
            let indexesOfObservationsNeedingHighlighted = [];

            let tableRecords = this.$refs.observationsTable.$refs.grdTableList.getCurrentViewRecords();

            tableRecords.map((obs, index) => {
                if (obs.IsContinuousComplete === false) {
                    indexesOfObservationsNeedingHighlighted.push(index);
                }
            });

            if (indexesOfObservationsNeedingHighlighted.length == 0) {
                return;
            }

            let rows = this.$refs.observationsTable.$refs.grdTableList.getDataRows();
            for (var i = 0; i < rows.length; i++) {
                rows[i].classList.remove('continuous-row');

                if (indexesOfObservationsNeedingHighlighted.includes(i)) {
                    rows[i].classList.add('continuous-row');
                }
            }
        },
        saveObservation(addAnother) {
            if (this.dto.FaultCodeID == null || this.dto.FaultCodeID == '') {
                return;
            }

            let observationCleanup = (addAnother) => {
                this.showInputErrors = false;
                this.nextContinuousValue = '';

                if (addAnother === false || addAnother === null) {
                    // If we're not adding another, setting the DTOs back to default so the confirm close / dto changed logic doesn't get triggered when trying to close the sidebar
                    this.dto = this.defaultDto;
                    this.originalDto = this.defaultDto;
                    this.toggleObservationSidebar('Add');
                } else {
                    this.dto.FaultCodeID = null;
                    this.dto.Distance = 0;
					this.dto.Fields = [];
                }
            };

            if (this.dto.Fields[15].Value == true) {
                if (this.dto.Fields[16].Value == '') {
                    this.dto.Fields[16].Value = this.nextContinuousValue;
                }
            } else {
                this.dto.Fields[16].Value = '';
            }

            this.dto.SessionID = this.sessionID;
			this.dto.VideoName = this.selectedVideo.VideoName || '';
			this.dto.ScreenshotBase64 = '';

			if (this.mode === 'Edit') {
				this.takePicture = false;
			}

			if (this.takePicture && this.selectedVideo !== '') {
				this.dto.ScreenshotBase64 = this.takeScreenshotFromVideo();
			}

            if (this.dto.ObservationID == null || this.dto.ObservationID == -1) {
                this.$store
                    .dispatch('ObservationModule/createObservation', this.dto)
                    .then(() => {
                        observationCleanup(addAnother);
                    })
                    .catch((error) => {
                        this.handleFieldError(error.response.data.ErrorDetail);
                    });
            } else {
                this.$store
                    .dispatch('ObservationModule/editObservation', this.dto)
                    .then(() => {
                        observationCleanup(addAnother);
                    })
                    .catch((error) => {
                        this.handleFieldError(error.response.data.ErrorDetail);
                    });
            }
        },
		handleFieldError(error) {
			this.showInputErrors = true;
			let errorFocusTimer = setInterval(() => {
				if (this.$store.state.ModalModule.ModalsOpened == 0) {
					let id = 'field' + error.FieldName;

					if (this.$refs[id]) {
						if (this.$refs[id][0]) {
							this.$refs[id][0].focusIn()
						} else {
							this.$refs[id].ej2Instances.focusIn();
						}
					}

					clearInterval(errorFocusTimer);
				}
			}, 250);
		},
        closeObservationSidebar() {
            this.toggleObservationSidebar();
        },
        editObservation(observation, completeContinuous = false, openToImagesTab = false) {
            this.$store.dispatch('ObservationModule/getObservationById', observation.ObservationID)
			.then((response) => {
                this.showInputErrors = false;	
				this.selectedImageRow = null;		

                if (this.isSidebarOpened == false) {
                    this.toggleObservationSidebar('Edit');
                } else {
                    this.mode = 'Edit';
                }

                let previousContinuousValue = response.Fields[16].Value;
                this.dto.Fields = [];
                this.dto.Distance = null;

                setTimeout(() => {
                    this.dto = DMUtils.copy(response);
                    this.getAddEditObservationDropdownOptions();
					observationVideo.pause();
					this.updateTimestamp();

                    if (completeContinuous) {
                        let startContinuousValue = previousContinuousValue;
                        let finishedContinuousValue = startContinuousValue.substring(1); // Get continuous value minus first character
                        this.dto.Fields[16].Value = 'F' + finishedContinuousValue; // Set finalized continuous value to the start cont value with F instead of S (ex. S02 --> F02)
                        this.nextContinuousValue = this.dto.Fields[16].Value;

                        this.mode = 'CompleteContinuous';
                        this.dto.ObservationID = -1;
                        this.dto.LastModifiedDate = '';
                        this.dto.VideoName = '';
						this.$refs.obsDistance.focusIn();
                    }

                    this.originalDto = DMUtils.copy(this.dto); // Setting originalDTO here for detecting changes after endpoint call / altering for continous observations
                    if (openToImagesTab) {
                        this.changeTab('images');

						setTimeout(() => {
							this.selectedImageRow = this.observationImages[0];
							this.$refs.imagesTable.$refs.grdTableList.selectRow(0)	
						}, 100)
                    } else {
						this.changeTab('observations');
					}
                }, 100);
            });
        },
        deleteObservation() {
            this.$store.dispatch('ObservationModule/deleteObservation', this.selectedRow.rowData);
        },
        completeContinuousObservation(observation) {
            let completeContinuous = true;
            this.editObservation(observation, completeContinuous);
        },
        observationsTableActionOnClick(args) {
            if (args.item.text === 'Jump To') {
                this.jumpToSpotInVideoByObservation(this.selectedRow.rowData.VideoPosition);
            } else if (args.item.text === 'Edit Observation' || args.item.text === 'View Observation') {
                this.jumpToSpotInVideoByObservation(this.selectedRow.rowData.VideoPosition);
                this.editObservation(this.selectedRow.rowData);
            } else if (args.item.text === 'Delete') {
                if (this.isEditModeEnabled == false) {
                    const message = 'Unable to delete observations without entering edit mode.';
                    const title = 'Warning';
                    const callback = null;

                    DMUtils.alertShow(message, title, callback);
                    return;
                }

                const message = 'Are you sure you want to delete this observation?';
                const title = 'Confirm';
                const confirmCallback = this.deleteObservation;

                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'Cancel',
                });
            } else if (args.item.text === 'Complete Continuous') {
                this.completeContinuousObservation(this.selectedRow.rowData);
            } else if (args.item.text === 'Add / Edit Images' || args.item.text === 'View Images') {
                this.jumpToSpotInVideoByObservation(this.selectedRow.rowData.VideoPosition);
                let completeContinuous = false;
                let openToImagesTab = true;
                this.editObservation(this.selectedRow.rowData, completeContinuous, openToImagesTab);
            }
        },
        toggleObservationSidebar(mode) {
            if (mode != null) {
                this.mode = mode;
            }

			this.selectedTab = 'observations';

            if (this.isSidebarOpened) {
                let closeSlider = () => {
                    this.$refs.observationPageSidebar.toggle();
                    this.dto = DMUtils.copy(this.defaultDto);
                    this.selectedObservationType = null;
                    this.showInputErrors = false;
                    this.isSidebarOpened = !this.isSidebarOpened;
                    this.selectedImageRow = null;
                };

                let showConfirmClose = false;
                if (this.isEditModeEnabled) {
                    let currentDto = DMUtils.copy(this.dto);
                    let dtoBeforeChangesWereMade = DMUtils.copy(this.originalDto);

                    if (
                        JSON.stringify(currentDto.Fields) != JSON.stringify(dtoBeforeChangesWereMade.Fields) ||
                        currentDto.FaultCodeID != dtoBeforeChangesWereMade.FaultCodeID ||
                        currentDto.Distance != dtoBeforeChangesWereMade.Distance
                    ) {
                        showConfirmClose = true;
                    }
                }

                if (showConfirmClose) {
                    DMUtils.confirmClose(closeSlider);
                } else {
                    closeSlider();
                }
            } else {
                this.$refs.observationPageSidebar.toggle();
                this.isSidebarOpened = !this.isSidebarOpened;

                // Only setting originalDto here for add as the edit function handles both edit mode and complete continuous after endpoint calls and altering of the DTO

                if (this.mode == 'Add') {
                    this.originalDto = DMUtils.copy(this.dto);
					this.takePicture = true;
                }
            }
        },
        navigateToHeadersForSession() {
            this.$store.commit('SessionsModule/setNavigatedToHeadersFrom', 'observations');
            this.$router.push({ name: 'headers', query: { id: this.sessionID } });
        },
        getNextContValueAndDetermineWeightUpdates(args) {
            if (args.checked == true && this.dto.Fields[16].Value == '') {
                this.$store
                    .dispatch(
                        'ObservationModule/getNextContinuousValue',
                        this.$store.state.ObservationModule.Session.SessionID
                    )
                    .then((nextContValueObj) => {
                        this.nextContinuousValue = nextContValueObj.DefectCode;
                    });
            } else {
                // If we unchecked the cont checkbox we don't need to get a new defect code, but we do need to see if we need to update the struct/maint weight value

                this.nextContinuousValue = this.dto.Fields[16].Value;
            }
        },
        jumpToSpotInVideoByObservation(timestamp) {
            observationVideo.currentTime = timestamp;
        },
        highlightAndScrollToRow(observation) {
            let tableRecords = this.$refs.observationsTable.$refs.grdTableList.getCurrentViewRecords();
            let rowIndex = tableRecords.findIndex((x) => x.ObservationID == observation.ObservationID);

            this.$refs.observationsTable.$refs.grdTableList.selectRow(rowIndex);
        },
		setVideoFromDropdown(args) {
			let videoLocation = '';
			if (args.itemData) {
				this.selectedVideo = args.itemData;
				videoLocation = this.selectedVideo.VideoLocation.replace(/^https?:/, '').replace(/3.15.106.54/, window.baseURL); 
			} else {
				this.selectedVideo = '';
			}

			observationVideo.firstChild.src = videoLocation;
			observationVideo.load();
		},
		setVideo() {
			let videoLocation = '';
			if (this.videoLocations.length) {
				this.selectedVideo = this.videoLocations[0];
				videoLocation = this.selectedVideo.VideoLocation.replace(/^https?:/, '').replace(/3.15.106.54/, window.baseURL);
			}

			observationVideo.firstChild.src = videoLocation;
			observationVideo.load();	
		},
		updateTimestamp() {
			let videoPosition;

			if (this.isEditModeEnabled) {
				videoPosition = observationVideo.currentTime;
			} else {
				videoPosition = this.dto.VideoPosition;
			}
			
			let roundedTimestamp = Math.floor(DMUtils.round(videoPosition, 2));
			this.dto.VideoPosition = roundedTimestamp;
			this.displayTimestamp = new Date(roundedTimestamp * 1000).toISOString().slice(11, 19);
		},
    },
    created() {
        this.$store.commit('setUseNavigationWarning', false);
		this.$store.commit('setGisAssetID', null);
        this.defaultDto = DMUtils.copy(this.dto);
        this.timeWeightsLastUpdated = moment();

        let sessionID = this.$route.query.id || -1;
        this.$store.dispatch('SessionsModule/getSessionByID', sessionID)
			.then((response) => {
				this.$store.commit('ObservationModule/setSession', response);
				this.setVideo();

				let templateName = this.$store.state.ObservationModule.Session.TemplateName;
				this.$store.dispatch('ObservationModule/getAllObservationCategoryOptions', templateName);
				this.getAddEditObservationDropdownOptions();
				this.$store.dispatch('ObservationModule/getObservations', this.sessionID);
				this.$store.dispatch('ObservationModule/getPipeNodes', this.sessionID);

				if (this.userIsEditUser) {
					this.$store.dispatch('ObservationModule/getHotbuttons', templateName);
				}
			});

		let self = this;
		this.observationImageColumns = [
			{
				type: 'template',
				width: '55px',
				template: () => {
					return {
						template: Vue.component('columnTemplate', {
							template: `	
										<div style="height: 38px;">
											<ejs-dropdownbutton
												v-if="$store.state.SessionsModule.IsEditModeEnabled && this.data.MediaID"
												:items="imageUploadTableActions"
												:select="select"
												iconCss="e-icons e-ellipsis-vert"
												cssClass="e-caret-hide"
											>
											</ejs-dropdownbutton>
										</div>`,
							data() {
								return {
									imageUploadTableActions: [
										{
											text: 'Edit'
										},
										{
											text: 'Delete'
										}
									]
								}
							},
							methods: {
								select(args) {
									self.imageUploadTableActionOnClick(args)
								},
							},
						})
					}
				}
			}, 
			{
				key: 'ImageFileName',
                label: 'Name',
                textAlign: 'Left',
                width: '350px',
			},
			{
                key: 'Remarks',
                label: 'Remarks',
                textAlign: 'Left',
            },
		]

        
        this.observationsTableColumns = [
            {
                type: 'template',
                width: '55px',
                template: () => {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                        :items= "ellipsisActions"
                                        :select= "select"
                                        iconCss= "e-icons e-ellipsis-vert"
                                        cssClass= "e-caret-hide">
                                    </ejs-dropdownbutton>`,
                            methods: {
                                select(args) {
                                    self.observationsTableActionOnClick(args);
                                },
                            },
                            data() {
                                return {
                                    data: {},
									editModeEllipsisActions: [
										{
                                            text: 'Jump To',
                                        },
                                        {
                                            text: 'Edit Observation',
                                        },
                                        {
                                            text: 'Add / Edit Images',
                                        },
                                        {
                                            text: 'Complete Continuous',
                                        },
                                        {
                                            text: 'Delete',
                                        },
									],
									editModeNonContinuousEllipsisActions: [
										{
                                            text: 'Jump To',
                                        },
                                        {
                                            text: 'Edit Observation',
                                        },
                                        {
                                            text: 'Add / Edit Images',
                                        },
                                        {
                                            text: 'Delete',
                                        },
									],
									viewModeEllipsisActions: [
										{
                                            text: 'Jump To',
                                        },
                                        {
                                            text: 'View Observation',
                                        },
                                        {
                                            text: 'View Images',
                                        },
									]
                                };
                            },
                            computed: {
								isEditModeEnabled() {
									return this.$store.state.SessionsModule.IsEditModeEnabled;
								},
                                ellipsisActions() {
									if (this.isEditModeEnabled) {
										if (this.data.IsContinuousComplete) {
											return this.editModeNonContinuousEllipsisActions;
										}
										return this.editModeEllipsisActions;
									} else {
										return this.viewModeEllipsisActions;
									}
                                },
                            },
                        }),
                    };
                },
            },
            {
                key: 'Distance',
                label: 'Distance',
                textAlign: 'Right',
                width: '110px',
            },
            {
                key: 'FaultCode',
                label: 'Code',
                textAlign: 'Left',
                headerTextAlign: 'Left',
                width: '100px',
            },
            {
                key: 'FaultCodeName',
                label: 'Description',
                textAlign: 'Left',
            },
            {
                key: 'ContinuousDefect',
                label: 'Continuous',
                textAlign: 'Left',
                width: '150px',
            },
            {
                key: 'StructWeight',
                label: 'Structural',
                textAlign: 'Right',
                width: '120px',
            },
            {
                key: 'MaintWeight',
                label: 'Maintainence',
                textAlign: 'Right',
                width: '150px',
            },
        ];

        this.imageUploadColumns = [
            {
                type: 'iconTemplate',
                iconName: 'ellipsis',
                ellipsisActions: this.imageUploadTableActions,
                iconOnClick: this.imageUploadTableActionOnClick,
                width: '55px',
            },
            {
                key: 'ImageName',
                label: 'ImageName',
                textAlign: 'Right',
                width: '110px',
            },
        ];
    },
};
</script>
