const modalModule = {
    namespaced: true,
    state: () => ({
        ModalsOpened: [],
    }),
    mutations: {
        openModal(state, modalComponent) {
            if (modalComponent.modalData === undefined || modalComponent.modalData === null) {
                modalComponent.modalData = {};
            }

            state.ModalsOpened.push(modalComponent);
        },
        closeModal(state, modalID) {
            state.ModalsOpened = state.ModalsOpened.filter(m => m.modalID !== modalID);
            document.getElementById(modalID).parentElement.remove();
        },
    },
    getters: {
        ModalsOpened(state) {
            return state.ModalsOpened;
        },
    }
};

export default modalModule;