<template src="./app.html"></template>

<script>
import dmAppMixin from '../dmFramework/dmJS/dmAppMixin.js';
import signInPage from './views/authorization/signInPage/signInPage.vue';
import sessionManagementPage from './views/sessionManagement/sessionManagementPage/sessionManagementPage.vue';
import headersPage from './views/headers/headersPage/headersPage.vue';
import observationsPage from './views/observations/observationsPage/observationsPage.vue';
import userManagementPage from './views/userManagement/usersPage.vue';
import usersEditModal from './views/userManagement/usersEditModal/usersEditModal.vue';
import userProfilePage from './views/userManagement/userProfile.vue';
import setHotkeyModal from './views/sessionManagement/components/setHotKeyModal/setHotKeyModal.vue';
import addImageModal from './views/observations/components/addImageModal/addImageModal.vue';
import manageVideosModal from './views/observations/components/manageVideosModal/manageVideosModal.vue';
import addVideoModal from './views/observations/components/manageVideosModal/addVideoModal/addVideoModal.vue';

export default {
    name: 'app',
    mixins: [dmAppMixin],
    components: {
        signInPage,
        sessionManagementPage,
        setHotkeyModal,
        headersPage,
        observationsPage,
        addImageModal,
        manageVideosModal,
        addVideoModal,
        userManagementPage,
        usersEditModal,
        userProfilePage,
    },
    data() {
        return {
            sidebarVisible: ['sessionManagement', 'observations', 'users', 'userProfile'],
            mnuSidebarItems: [
                {
                    text: 'Session Management',
                    onClick: this.mnuSessionManagementOnClick,
                    iconCss: 'fa fa-search dm-color-white main-menu-icon',
                },
                {
                    text: 'User Profile',
                    onClick: this.mnuUserProfileOnClick,
                    iconCss: 'fa fa-user dm-color-white main-menu-icon',
                },
                {
                    text: 'Go To GIS',
                    onClick: this.mnuGISOnClick,
                    iconCss: 'fa fa-map dm-color-white main-menu-icon',
                },
                {
                    text: 'Support',
                    onClick: this.mnuSupportOnClick,
                    iconCss: 'fa fa-question-circle dm-color-white main-menu-icon',
                },
            ],
            mnuSidebarItemsAdmin: [
                {
                    text: 'Session Management',
                    onClick: this.mnuSessionManagementOnClick,
                    iconCss: 'fa fa-search dm-color-white main-menu-icon',
                },
                {
                    text: 'User Management',
                    onClick: this.mnuUserManagementOnClick,
                    iconCss: 'fa fa-users dm-color-white main-menu-icon',
                },
                {
                    text: 'Go To GIS',
                    onClick: this.mnuGISOnClick,
                    iconCss: 'fa fa-map dm-color-white main-menu-icon',
                },
                {
                    text: 'Support',
                    onClick: this.mnuSupportOnClick,
                    iconCss: 'fa fa-question-circle dm-color-white main-menu-icon',
                },
            ],
            mnuSidebarItemsGuest: [
                {
                    text: 'Session Management',
                    onClick: this.mnuSessionManagementOnClick,
                    iconCss: 'fa fa-search dm-color-white main-menu-icon',
                },
                {
                    text: 'Go To GIS',
                    onClick: this.mnuGISOnClick,
                    iconCss: 'fa fa-map dm-color-white main-menu-icon',
                },
                {
                    text: 'Support',
                    onClick: this.mnuSupportOnClick,
                    iconCss: 'fa fa-question-circle dm-color-white main-menu-icon',
                },
            ],
            sidebarCollapsed: false,
        };
    },
    watch: {
        isSidebarOpen: {
            handler(val) {
                if (val) {
                    this.$refs.sidebarInstance.show();
                } else {
                    this.$refs.sidebarInstance.hide();
                }
            },
        },
    },
    computed: {
        activeRoute() {
            return this.$route.name;
        },
        isSidebarVisible() {
            if (this.activeRoute === 'observations' && this.$store.state.SessionsModule.IsEditModeEnabled) {
                return false;
            } else {
                return this.sidebarVisible.includes(this.activeRoute);
            }
        },
        isSidebarOpen() {
            return this.$store.state.IsSidebarOpen;
        },
        modalsOpened() {
            return this.$store.getters['ModalModule/ModalsOpened'];
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
    },
    methods: {
        toggleClick() {
            this.$store.commit('setIsSidebarOpen', !this.$store.state.IsSidebarOpen);
        },
        logoOnClick() {},
        mnuOnClick(args) {
            if (args.item.onClick) {
                args.item.onClick();
            }
        },
        mnuSessionManagementOnClick() {
            this.$router.push({ name: 'sessionManagement' });
        },
        mnuUserManagementOnClick() {
            this.$router.push({ name: 'users' });
        },
        mnuUserProfileOnClick() {
            this.$router.push({ name: 'userProfile' });
        },
        mnuGISOnClick() {
            let link = document.createElement('a');
            link.href = this.$store.state.AuthorizationModule.SignedInUser.ExternalGisUrl || '';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        mnuSupportOnClick() {
            let link = document.createElement('a');
            link.href = 'https://posmsoftware.com/support';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        mnuSignOutOnClick() {
            this.$store.dispatch('AuthorizationModule/signOut', 'signin');
        },
        preventNav(e) {
            if (this.$store.state.UseNavigationWarning || this.$store.state.SessionsModule.IsEditModeEnabled) {
                e.preventDefault();
                e.returnValue = '';
            }
        },
        initApplication() {
            let assetID = this.$route.query.assetID;
            let authToken = DMUtils.getAuthToken();

            if (assetID) {
                this.$store.commit('setGisAssetID', assetID);
                let dispatch;
                let payload;

                if (authToken) {
                    dispatch = 'AuthorizationModule/signInWithAuthToken';
                    payload = { authToken };
                } else {
                    dispatch = 'AuthorizationModule/signIn';
                    payload = { credentials: { Username: 'Guest' }, nextPage: '' };
                }

                this.$store.dispatch(dispatch, payload).then(() => {
                    this.routeUserFromGis(assetID);
                });
            } else {
                if (authToken) {
                    this.signInAndRouteUser(authToken);
                } else {
                    this.$router.push({ name: 'signin' });
                }
            }
        },
        routeUserFromGis(assetID) {
            this.$store.dispatch('SessionsModule/getSessionByAssetID', assetID).then((response) => {
                if (response.SessionID > -1) {
                    this.$router.push({ name: 'observations', query: { id: response.SessionID } });
                }
                if (response.SessionID === -1) {
                    this.$router.push({ name: 'sessionManagement' });
                }
            });
        },
        signInAndRouteUser(authToken) {
            let nextPage = 'sessionManagement';
            let query = '';

            if (localStorage.getItem('posm-page')) {
                if (localStorage.getItem('posm-page') !== 'null') {
                    nextPage = localStorage.getItem('posm-page');

                    if (localStorage.getItem('posm-query')) {
                        if (localStorage.getItem('posm-query') !== 'null') {
                            query = JSON.parse(localStorage.getItem('posm-query'));
                        }
                    }
                }
            }

            setTimeout(() => {
                this.$store.dispatch('AuthorizationModule/signInWithAuthToken', {
                    authToken: authToken,
                    nextPage: nextPage,
                    query: query,
                });
            }, 1000);
        },
    },
    created() {
        window.addEventListener('beforeunload', this.preventNav);
        this.initApplication();
    },
};
</script>

<style>
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-navigations/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-popups/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-inputs/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/bootstrap-dark.css';
@import '../node_modules/@syncfusion/ej2-vue-querybuilder/styles/bootstrap-dark.css';

</style>
