<template src="./sessionsTable.html"></template>
<script>
import { Aggregate, Page, Selection, Sort, ColumnChooser, Toolbar, Reorder, Resize } from '@syncfusion/ej2-vue-grids';

export default {
    name: 'sessions-table',
    props: ['items', 'selectRow', 'pageSettings', 'dataStateChange', 'sortOptions', 'resetPage'],
    data() {
        return {
            customColumnsSettings: [],
            toolbarOptions: ['ColumnChooser'],
        };
    },
    watch: {
        customColumnsSettings: {
            deep: true,
            handler(oldVal, newVal) {
                if (newVal.length > 0) {
                    this.$store.dispatch('SessionsModule/updateUserColumns', newVal);
                }
            },
        },
		resetPage: {
			handler(val) {
				if (val) {
					this.$refs.sessionsTable.goToPage(1);
				}
			}
		}
    },
    methods: {
        actionBegin(args) {
            if (args.requestType === 'refresh') {
                this.pageSettings.currentPage = 1;
            }
        },
        actionComplete(args) {
            if (args.requestType === 'reorder') {
                this.setColumnOrder();
            }
        },
        formatBooleanValues(field, data, column) {
            if (data[field] === true) {
                return '<i class="fa fa-check"></i>';
            } else {
                return '<i class="fa fa-times"></i>';
            }
        },
        getColumnType(field) {
            if (field.InputDataType === 'Numeric' || field.InputDataType === 'Decimal') {
                return 'number';
            }
            if (field.InputDataType === 'Date') {
                return 'date';
            }
            if (field.InputDataType === 'Boolean') {
                return 'boolean';
            }

            return 'text';
        },
        getColumnWidth(field) {
            if (field.Width) {
                return field.Width;
            }
            return '150px';
        },
        getFormat(field) {
            if (field.InputDataType === 'Date') {
                return 'M/dd/yyyy h:mm a';
            }
        },
        getGridAlignment(dataType) {
            if (dataType === 'Numeric' || dataType === 'Decimal') {
                return 'Right';
            }

            if (dataType === 'Boolean') {
                return 'Center';
            }

            return 'Left';
        },
        renderSortingIcon(args) {
            if (args.cell.column.allowSorting) {
                args.node.classList.add('customicon');
            }
        },
        resizeStop(args) {
            let resizedColumn = this.customColumnsSettings.find((col) => {
                return col.Name === args.column.field;
            });

            if (resizedColumn) {
                resizedColumn.Width = args.column.width;
            }
        },
        rowSelecting() {
            this.$refs.sessionsTable.clearSelection();
        },
        setColumnOrder() {
            let columnFields = this.$refs.sessionsTable
                .getColumns()
                .filter((col) => {
                    //Filter out the checkbox selector column which has no "field" value
                    return col.field;
                })
                .map((col) => {
                    return col.field;
                });

            this.customColumnsSettings.sort((a, b) => {
                return columnFields.indexOf(a.Name) - columnFields.indexOf(b.Name);
            });
        },
        setColumnVisibility() {
            let cols = this.$refs.sessionsTable.getColumns().filter((col) => {
                return col.field;
            });

            let visibleColumns = cols
                .filter((col) => {
                    return col.visible;
                })
                .map((col) => {
                    return col.field;
                });

            //Show the SessionID column if the user attempts to hide all columns in the grid
            if (visibleColumns.length === 0) {
                visibleColumns.push('SessionID');
                this.$refs.sessionsTable.showColumns(['Session ID'], 'headerText');
            }

            this.customColumnsSettings.forEach((col) => {
                if (visibleColumns.indexOf(col.Name) > -1) {
                    col.IsVisible = true;

                    if (!col.Width) {
                        col.Width = '150px';
                    }
                } else {
                    col.IsVisible = false;
                }
            });
        },
        setUserColumnPreferences() {
            let hiddenColumnHeaders = this.customColumnsSettings
                .filter((col) => {
                    return !col.IsVisible;
                })
                .map((col) => {
                    return col.LabelText;
                });

            this.$refs.sessionsTable.hideColumns(hiddenColumnHeaders, 'headerText');
        },
    },
    mounted() {
        let columnChooserOkButton = document.querySelector('.e-cc_okbtn');
        columnChooserOkButton.addEventListener('click', this.setColumnVisibility);
		
		setTimeout(() => {
			this.$refs.sessionsTable.selectRow(this.$store.state.SessionsModule.SelectedSessionIndex);
		}, 1000)
    },
    created() {
        this.$store.dispatch('SessionsModule/getUserColumns').then(() => {
            this.customColumnsSettings = DMUtils.copy(this.$store.state.SessionsModule.UserColumns);
        });

        let searchTerms = JSON.parse(localStorage.getItem('posm-searchTerms'));

        if (searchTerms) {
            this.pageSettings.currentPage = searchTerms.PageNumber;
			this.pageSettings.pageSize = searchTerms.NumberOfRecordsPerPage;
        }
    },
    provide: {
        grid: [Aggregate, Page, Selection, Sort, ColumnChooser, Toolbar, Reorder, Resize],
    },
};
</script>
