<template src="./userProfile.html"></template>

<script>
export default {
    name: 'user-profile-page',
    data() {
        return {
            dto: {},
        };
    },
    methods: {
        routeAway() {
            this.$router.push({ name: 'sessionManagement' });
        },
        btnSaveOnClick() {
			this.$store.dispatch('UsersModule/updatePassword', this.dto);
        },
        btnCancelOnClick() {
            this.routeAway();
        },
    },
    created() {
		this.$store.commit('setUseNavigationWarning', false);
		this.$store.commit('SessionsModule/setIsEditModeEnabled', false);
		
        this.$store.dispatch('UsersModule/clearStore').then(() => {
            this.$store
                .dispatch('UsersModule/getUserByID', this.$store.state.AuthorizationModule.SignedInUser.UserID)
                .then(() => {
                    this.dto = this.$store.state.UsersModule.CurrentUser;
                });
        });
    },
};
</script>
