export default {
	getAllUsers() {
        return DMUtils.http({
            method: 'GET',
            params: {},
            url: '/Users/GetAll',
        }).then((response) => {
            return response;
        });
    },
	getUserByID(userID) {
		return DMUtils.http({
			method: 'GET',
			params: { UserID: userID },
			url: '/Users/GetByID'
		}).then((response) => {
			return response;
		})
	},
    addUser(user) {
		let dto = {
			Username: user.Username || '',
			Password: user.NewPassword || '',
			//ConfirmPassword: user.NewPasswordConfirm,
			Role: user.Role || '',
		}

        return DMUtils.http({
            method: 'POST',
            data: dto,
            url: '/Users/Create',
        }).then((response) => {
            return response;
        });
    },
    updateUserByAdmin(user) {
		let dto = {
			UserID: user.UserID,
			Username: user.Username,
			Role: user.Role,
			RowVersion: user.RowVersion,
			IsActive: user.IsActive,
		};

        return DMUtils.http({
            method: 'POST', 
            data: dto,
            url: '/Users/UpdateByAdmin',
        }).then((response) => {
            return response;
        });
    },
	updateUser(user) {
		let dto = {
			UserID: user.UserID,
			Username: user.Username,
			RowVersion: user.RowVersion,
		}

		return DMUtils.http({
			method: 'POST',
			data: dto,
			url: '/Users/Update',
		}).then((response) => {
			return response;
		})
	},
	updatePasswordByAdmin(user) {
		let dto = {
			UserID: user.UserID,
			NewPassword: user.NewPassword,
			NewPasswordConfirm: user.NewPasswordConfirm,
		};

		return DMUtils.http({
			method: 'POST',
			data: dto,
			url: '/Auth/UpdatePasswordByAdmin'
		}).then((response) => {
			return response;
		})
	},
	updatePassword(user) {
		let dto = {
			CurrentPassword: user.CurrentPassword || '',
			NewPassword: user.NewPassword || '',
			NewPasswordConfirm: user.NewPasswordConfirm || '',
		}

		return DMUtils.http({
			method: 'POST',
			data: dto,
			url: '/Auth/UpdatePassword',
		}).then((response) => {
			return response;
		})
	},
	deleteUser(user) {
		return DMUtils.http({
			method: 'POST',
			data: user,
			url: '/Users/Delete'
		}).then((response) => {
			return response;
		})
	}
}