import usersEndpoints from './users.endpoints';

const usersModule = {
    namespaced: true,
    state: () => ({
        Users: [],
		CurrentUser: {},
        // Database: {
        //     DatabaseSelected: '',
        //     DatabaseID: 0,
        // },
    }),
    mutations: {
        clearStore(state) {
            state.Users = [];
        },
        setUsers(state, users) {
            state.Users = users;
        },
		setCurrentUser(state, user) {
			state.CurrentUser = user;
		}
        // setDatabase(state, database) {
        //     state.Database = database;
        // },
    },
    actions: {
        getAll({ commit }) {
            return usersEndpoints.getAllUsers()
			.then((response) => {
                commit('setUsers', response);
            });
        },
		getUserByID({commit}, userID) {
			return usersEndpoints.getUserByID(userID)
			.then((response) => {
				commit('setCurrentUser', response);
			})
		},
        addUser({ dispatch }, user) {
            return usersEndpoints.addUser(user)
			.then(() => {
                return dispatch('getAll'); T
            });
        },
        updateUserByAdmin({ dispatch }, user) {
            return usersEndpoints.updateUserByAdmin(user)
			.then(() => {
				return dispatch('getAll');
            });
        },
		updateUser({ dispatch, state }, user) {
			return usersEndpoints.updateUser(user)
			.then(() => {
				return dispatch('getUserByID', state.CurrentUser.UserID);
			});
		},
		updatePasswordByAdmin({ dispatch }, dto) {
			return usersEndpoints.updatePasswordByAdmin(dto)
			.then(() => {
				return dispatch('getAll');
			})
		},
		updatePassword({ dispatch }, dto) {
			return usersEndpoints.updatePassword(dto)
			.then(() => {
				return dispatch('getAll');
			})
		},
		deleteUser({ dispatch }, dto) {
			return usersEndpoints.deleteUser(dto) 
			.then(() => {
				return dispatch('getAll');
			})
		},
        clearStore({ commit }) {
            commit('clearStore');
        },
    },
};

export default usersModule;
