<template src="./signInPage.html"></template>

<script>
export default {
    name: 'sign-in-page',
    data() {
        return {
            dto: {},
            showForgotPassword: false,
        };
    },
    methods: {
        signIn() {
			let nextPage = 'sessionManagement';

			if (this.$store.state.GisAssetID) {
				nextPage = '';
			}

            this.$store.dispatch('AuthorizationModule/signIn', {
				credentials: this.dto, 
				nextPage: nextPage
				})
				.then(() => {
					if (this.$store.state.GisAssetID) {
						this.routeUserFromGis(this.$store.state.GisAssetID);
					}
				})
        },
		signInAsGuest() {
			this.$store.dispatch('AuthorizationModule/signIn', {
				credentials: { Username: 'Guest' }, 
				nextPage: 'sessionManagement'
				});
		},
		routeUserFromGis(assetID) {
            this.$store.dispatch('SessionsModule/getSessionByAssetID', assetID)
				.then((response) => {
					if (response.SessionID > -1) {
						this.$router.push({ name: 'observations', query: { id: response.SessionID } });
					}
					if (response.SessionID === -1) {
						this.$router.push({ name: 'sessionManagement' });
					}
				});
        },
    },
	created() {
		this.$store.commit('SessionsModule/setIsEditModeEnabled', false);
	}
};
</script>
