<template src="./sessionManagementPage.html"></template>

<script>
import advancedSearch from '../components/advancedSearch/advancedSearch.vue';
import sessionsTable from '../components/sessionsTable/sessionsTable.vue';
import databaseConnection from '../../../components/DatabaseConnection/DatabaseConnection.vue';
import rightButtonMenu from '../../../components/RightButtonMenu/rightButtonMenu.vue';

export default {
    name: 'session-management-page',
    components: {
		'advanced-search': advancedSearch,
        'sessions-table': sessionsTable,
        'database-connection': databaseConnection,
        'right-button-menu': rightButtonMenu,
    },
    data() {
        return {
            autoCompleteValues: {
                ProjectName: [],
                StartID: [],
				EndID: [],
                AssetID: [],
                AssetLocation: [],
                DataValue: [],
            },
            autoCompleteValueTimer: null,
			defaultAdvancedSearchRules: {
				condition: 'and',
                rules: [{}]
			},
			defaultSearchTerms: {
				StartDate: '',
				EndDate: '',
				TemplateName: '',
				ProjectName: '',
				StartID: '',
				EndID: '',
				AssetID: '',
				FlowDirection: '',
				AssetLocation: '',
				PageNumber: 1,
				NumberOfRecordsPerPage: 100,
				SortByColumn: 'Date',
				SortDirection: 'Descending',
				AdvancedSearch: ''
			},
            hotKeyOptions: [
                {
                    text: 'Set Hot Key',
                    OnClick: this.setHotKey,
                },
                {
                    text: 'Delete Hot Key',
                    OnClick: this.deleteHotKey,
                },
            ],
            pageSettings: { pageSizes: true, pageSize: 100, pageSizes: [50, 100, 200, 300], currentPage: 1},
			resetPage: false,
            rightMenuButtons: [
                {
                    text: 'Start New Session',
                    icon: 'fa-plus-circle',
                    color: 'brand-color-secondary',
                    onClick: this.btnStartNewSessionOnClick,
					isVisible: true,
                },
                {
                    text: 'Search Sessions',
                    icon: 'fa-search',
                    color: 'brand-color-primary',
                    onClick: this.toggleSidebar,
					isVisible: true,
                },
            ],
			searchIncludes: {
				simpleSearch: '',
				advancedSearch: '',
			},
            searchTerms: {},
			selectedSavedSearch: null,
			sortOptions: null,
        };
    },
    computed: {
        advancedSearchDataFields() {
            return this.$store.state.SessionsModule.AdvancedSearchDataFields;
        },
		advancedSearchRules() {
			return this.$store.state.SessionsModule.AdvancedSearchRules;
		},
    },
    methods: {
        btnEditHeadersOnClick() {
            if (!this.checkRowSelected()) {
                return;
            }

            let selectedSession = this.$refs.sessionsTable.$refs.sessionsTable.getSelectedRecords()[0].SessionID;
			let selectedIndex = this.$refs.sessionsTable.$refs.sessionsTable.getSelectedRowIndexes()[0];
			this.$store.commit('SessionsModule/setSelectedSessionIndex', selectedIndex);
			this.$store.commit('SessionsModule/setNavigatedToHeadersFrom', 'sessionManagement');
			this.$router.push({ name: 'headers', query: {id: selectedSession} });
        },
        btnEditObservationsOnClick() {
            if (!this.checkRowSelected()) {
                return;
            }

            let selectedSession = this.$refs.sessionsTable.$refs.sessionsTable.getSelectedRecords()[0].SessionID;
			let selectedIndex = this.$refs.sessionsTable.$refs.sessionsTable.getSelectedRowIndexes()[0];
			this.$store.commit('SessionsModule/setSelectedSessionIndex', selectedIndex);
			this.$router.push({ name: 'observations', query: {id: selectedSession}})
        },
        btnResetFiltersOnClick() {
			this.searchTerms.AdvancedSearch = '';
			this.searchTerms.AssetID = '',
			this.searchTerms.AssetLocation = '';
			this.searchTerms.EndDate = '',
			this.searchTerms.EndID = '';
			this.searchTerms.FlowDirection = '';
			this.searchTerms.ProjectName = '';
			this.searchTerms.StartDate = '';
			this.searchTerms.StartID = '';
			this.searchTerms.TemplateName = '';
			this.searchTerms.Date = [];
			this.searchTerms.PageNumber = 1;

			this.resetPage = true;

			this.$store.dispatch('SessionsModule/getFieldsForSearch', this.searchTerms.TemplateName)
				.then(() => {
					return this.$store.dispatch('SessionsModule/getSessionsForSearch', this.searchTerms)
				})
				.then(() => {
					this.$store.commit('SessionsModule/setAdvancedSearchRules', {});
					this.$store.commit('SessionsModule/setSelectedTemplate', '');
					this.setSearchTermsString();
					this.selectedSavedSearch = null;

					localStorage.setItem('posm-searchTerms', JSON.stringify(this.searchTerms));
					localStorage.setItem('posm-advancedSearch', JSON.stringify(this.advancedSearchRules));
					this.resetPage = false;
				});
        },
        btnSearchOnClick() {
			this.$store.commit('SessionsModule/setAdvancedSearchRules', this.$refs.advancedSearch.$refs.advancedSearch.ej2Instances.getValidRules())

			this.searchTerms.AdvancedSearch = this.parseAdvancedSearchGroups(this.advancedSearchRules);

            this.$store.dispatch('SessionsModule/getSessionsForSearch', this.searchTerms)
				.then(() => {
					localStorage.setItem('posm-advancedSearch', JSON.stringify(this.advancedSearchRules));
					localStorage.setItem('posm-searchTerms', JSON.stringify(this.searchTerms));
					localStorage.setItem('posm-autoCompleteValues', JSON.stringify(this.autoCompleteValues));
					this.toggleSidebar();
					this.setSearchTermsString();
				});
        },
        btnStartNewSessionOnClick() {
            this.$store.dispatch('SessionsModule/getNewSession')
				.then(() => {
					localStorage.setItem('posm-headersMode', 'Add');
					this.$router.push({ name: 'headers' });
				});
        },
        checkRowSelected() {
            let selectedRows = this.$refs.sessionsTable.$refs.sessionsTable.getSelectedRecords();

            if (selectedRows.length === 0) {
                DMUtils.alertShow('You must select a session.', 'Information');
                return false;
            }

            return true;
        },
		deleteSearch(dto) {
			this.$store.dispatch('SessionsModule/deleteSavedSearch', dto);
		},
        getAutoCompleteValue(e, fieldName) {
            if (this.autoCompleteValueTimer) {
                clearTimeout(this.autoCompleteValueTimer);
            }

            if (e.text) {	
				setTimeout(() => {
					let noData = document.querySelector('.e-nodata');
					noData.textContent = 'Searching...'
				}, 100)

                this.autoCompleteValueTimer = setTimeout(() => {
                    let dto = {
                        FieldName: fieldName,
                        UserInput: e.text,
                        TemplateName: '',
                    };

                    this.$store.dispatch('SessionsModule/getAutoCompleteValue', dto)
						.then((response) => {
							this.autoCompleteValues[fieldName] = response;
						});
                }, 1000);
            }
        },
		getHotkeyClasses(key) {
			let classes = 'dm-color-white border-none app-btn-md hotkey';

			if (this.selectedSavedSearch === key.Number) {
				classes += ' hotkey-selected';
			}

			return classes;
		},
		getLocalStorageSearchTerms() {
			if (this.$store.state.GisAssetID) {
				this.loadGisSearch();
			}

			if (localStorage.getItem('posm-searchTerms')) {
				return JSON.parse(localStorage.getItem('posm-searchTerms'));
			} else {
				return DMUtils.copy(this.defaultSearchTerms);
			}
		},
		loadGisSearch() {
			let gisSearchTerms = DMUtils.copy(this.defaultSearchTerms);				
			let gisSearchUnformatted = { 
				condition: 'and',
				rules: [
				{
					label: "Asset ID",
					field: "AssetID",
					operator: "Equal",
					type: "string",
					value: this.$store.state.GisAssetID
				}
			]}

			gisSearchTerms.AdvancedSearch = this.parseAdvancedSearchGroups(gisSearchUnformatted);

			localStorage.setItem('posm-searchTerms', JSON.stringify(gisSearchTerms));
			localStorage.setItem('posm-advancedSearch', JSON.stringify(gisSearchUnformatted));
			this.$store.commit('setGisAssetID', null);
		},
        onOpenDateRangePicker(args) {
            args.popup.element.style.width = '515px';
            args.popup.position = { X: 'left', Y: 'bottom' };
            args.popup.dataBind();
        },
		parseAdvancedSearchGroups(advancedSearchRules) {
            let mainQuery = DMUtils.copy(advancedSearchRules);
            let group = [];
            let advancedSearch = {};

            if (DMUtils.isObjectEmpty(mainQuery) || mainQuery.rules.length === 0) {
				//If we have no advanced search query items, just return an empty string
                return '';
            } else if (mainQuery.rules.length === 1 && mainQuery.rules[0].condition) {
				//If there is a group without a main query, transfer the group rules into the main query
				//BE will not allow a group without a main query
				advancedSearch.Condition = mainQuery.rules[0].condition;
				advancedSearch.Rules = this.parseAdvancedSearchRules(mainQuery.rules[0].rules);
			} else {
				//This block means we have at least one main query
				//Only one group (besides the main query) is allowed by the UI

				//If the rule contains a "condition" property, it is its own group
				mainQuery.rules = mainQuery.rules.filter((rules) => {
					return !rules.condition;
				});

				group = advancedSearchRules.rules.filter((rules) => {
					return rules.condition;
				});

				advancedSearch.Condition = mainQuery.condition;
				advancedSearch.Rules = this.parseAdvancedSearchRules(mainQuery.rules);
			}

			if (group.length > 0 && group[0].rules && group[0].rules.length > 0) {
				//If we have group rules, parse them the same way and attach to the Group property
				advancedSearch.Group = {};
				advancedSearch.Group.Condition = group[0].condition;
				advancedSearch.Group.Rules = this.parseAdvancedSearchRules(group[0].rules);
			} else {
				//If we have no group rules, return an empty string
				advancedSearch.Group = '';
			}

            return advancedSearch;
        },
        parseAdvancedSearchRules(rules) {
            return rules.map((rule) => {
				let value;
				
				//Between operators come with two values. Other operators only have one. BE wants all values in an array, regardless of operator
				if (rule.operator && rule.operator.includes('Between')) {
					value = [...rule.value];
				} else {
					value = [rule.value];
				}

                return {
                    FieldName: rule.field || '',
					LabelText: rule.label || '',
                    DataType: rule.type || '',
                    Operator: rule.operator || '',
                    Values: value || [],
                };
            });
        },
        selectSavedSearch(args, search) {
			let dto = search;
			let advancedSearchJson = this.$refs.advancedSearch.$refs.advancedSearch.ej2Instances.getValidRules();
			let parsedAdvancedSearch = this.parseAdvancedSearchGroups(advancedSearchJson);

			dto.SearchParameters = {
				AdvancedSearch: parsedAdvancedSearch,
				AdvancedSearchJson: JSON.stringify(advancedSearchJson),
				AssetID: this.searchTerms.AssetID || '',
				AssetLocation: this.searchTerms.AssetLocation || '',
				EndDate: '',
				EndID: this.searchTerms.EndID || '',
				FlowDirection: this.searchTerms.FlowDirection || '',
				ProjectName: this.searchTerms.ProjectName || '',
				StartDate: '',
				StartID: this.searchTerms.StartID || '',
				TemplateName: this.searchTerms.TemplateName || ''
			 };

			 if (this.searchTerms.Date && this.searchTerms.Date.length) {
				dto.SearchParameters.StartDate = this.searchTerms.Date[0];
				dto.SearchParameters.EndDate = this.searchTerms.Date[1];
			 }

			if (args.item.text === 'Set Hot Key') {
				let modalName = 'setHotkeyModal';
            	let modalID = modalName + DMUtils.getRandomGuid();
            	let modalData = { dto: dto, title: 'Set Saved Search' };

            	let modalCreateDto = { modalName, modalID, modalData };
            	this.$store.commit('ModalModule/openModal', modalCreateDto);
			}

			if (args.item.text === 'Delete Hot Key') {
				let title = 'Confirm';
				let message = 'Are you sure you want to delete this search?';

				DMUtils.confirmShow(message, title, () => this.deleteSearch(dto), null, 'Yes', 'No');
			}

		},
        selectTemplate(args) {
			if (args.isInteracted) {
				this.$store.dispatch('SessionsModule/getFieldsForSearch', args.itemData.Value)
					.then(() => {
						this.$store.commit('SessionsModule/setAdvancedSearchRules', {})
						this.$store.commit('SessionsModule/setAdvancedSearchRules', DMUtils.copy(this.defaultAdvancedSearchRules));
						this.$store.commit('SessionsModule/setSelectedTemplate', args.itemData.Value);
					})
			}
        },
        sessionsTableDataStateChange(args) {
			let mostRecentSearchTerms = this.getLocalStorageSearchTerms();
            
			if (args.action.requestType === 'paging') {
				this.$refs.sessionSearch.hide();

                this.searchTerms.NumberOfRecordsPerPage = args.take;
				mostRecentSearchTerms.NumberOfRecordsPerPage = args.take;
                this.searchTerms.PageNumber = args.action.currentPage;
				mostRecentSearchTerms.PageNumber = args.action.currentPage;
				//this.pageSettings.currentPage = args.action.currentPage;

				this.$store.dispatch('SessionsModule/getSessionsForSearch', mostRecentSearchTerms);
				localStorage.setItem('posm-searchTerms', JSON.stringify(mostRecentSearchTerms));
            }

            if (args.action.requestType === 'sorting') {
				this.$refs.sessionSearch.hide();

				let sortDirection = 'Descending';

				if (args.sorted) {
					if (args.sorted[0].direction.toLowerCase() === 'ascending') {
						sortDirection = 'Ascending';
					}
	
					this.searchTerms.SortByColumn = args.sorted[0].name;
					mostRecentSearchTerms.SortByColumn = args.sorted[0].name;
					this.searchTerms.SortDirection = sortDirection;
					mostRecentSearchTerms.SortDirection = sortDirection;

					this.$store.dispatch('SessionsModule/getSessionsForSearch', mostRecentSearchTerms);
					localStorage.setItem('posm-searchTerms', JSON.stringify(mostRecentSearchTerms));
				}
            }
        },
		setAdvancedSearchTermsString(rules) {
			let searchTerms = [];

			if (rules.Rules) {
				rules.Rules.forEach((rule) => {
					let operatorString = '';

					switch(rule.Operator) {
						case 'Equal':
							operatorString = '=';
							break;
						case 'GreaterThanOrEqual':
							operatorString = '>=';
							break;
						case 'GreaterThan':
							operatorString = '>';
							break;
						case 'Between':
							operatorString = 'is between';
							break;
						case 'LessThanOrEqual':
							operatorString = '<=';
							break;
						case 'LessThan':
							operatorString = '<';
							break;
						case 'Not Between':
							operatorString = 'is not between';
							break;
						case 'NotEqual':
							operatorString = "does not equal";
							break;
						case 'StartsWith':
							operatorString = 'starts with';
							break;
						case 'EndsWith':
							operatorString = 'ends with';
							break;
						case 'Contains':
							operatorString = 'contains';
							break;
						default:
							operatorString = '';
					}

					let values = rule.Values;

					if (rule.DataType === 'date') {
						values = rule.Values.map((value) => {
							return moment.utc(value).format('M/D/YYYY');
						})
					}

					if (rule.Values.length === 2) {
						searchTerms.push(`${rule.LabelText} ${operatorString} ${values[0]} and ${values[1]}`);
					} else {
						searchTerms.push(`${rule.LabelText} ${operatorString} ${values[0]}`);
					}
				});
			}

			let string = searchTerms.join(' ' + rules.Condition + ' ');
			return string;
		},
        setSearchTermsString() {
			this.searchIncludes.simpleSearch = '';
			this.searchIncludes.advancedSearch = '';
            let simpleSearchArray = [];
			let advancedSearchArray = [];

            if (this.searchTerms.Date && this.searchTerms.Date.length) {
                simpleSearchArray.push(`Date Range:
                    ${DMUtils.formatDateDisplay(this.searchTerms.Date[0])}-
                    ${DMUtils.formatDateDisplay(this.searchTerms.Date[1])}`);
            }

            if (this.searchTerms.ProjectName) {
                simpleSearchArray.push(`Project Name: ${this.searchTerms.ProjectName}`);
            }

            if (this.searchTerms.TemplateName) {
                simpleSearchArray.push(`Template: ${this.searchTerms.TemplateName}`);
            }

            if (this.searchTerms.StartID) {
                simpleSearchArray.push(`Start ID: ${this.searchTerms.StartID}`);
            }

			if (this.searchTerms.EndID) {
                simpleSearchArray.push(`End ID: ${this.searchTerms.EndID}`);
            }

            if (this.searchTerms.AssetID) {
                simpleSearchArray.push(`Asset ID: ${this.searchTerms.AssetID}`);
            }

            if (this.searchTerms.AssetLocation) {
                simpleSearchArray.push(`Location: ${this.searchTerms.AssetLocation}`);
            }

            if (this.searchTerms.FlowDirection) {
                simpleSearchArray.push(`Direction: ${this.searchTerms.FlowDirection}`);
            }

			if (this.searchTerms.AdvancedSearch) {
				let advancedSearchStringMain = this.setAdvancedSearchTermsString(this.searchTerms.AdvancedSearch);
				let advancedSearchStringGroup = this.setAdvancedSearchTermsString(this.searchTerms.AdvancedSearch.Group);

				if (advancedSearchStringMain) {
					advancedSearchArray.push(advancedSearchStringMain);
				}

				if (advancedSearchStringGroup) {
					advancedSearchArray.push(advancedSearchStringGroup);
				}
			}

            this.searchIncludes.simpleSearch = simpleSearchArray.join(', ');
			this.searchIncludes.advancedSearch = advancedSearchArray.join('; ');
        },
        toggleSidebar() {
            this.$refs.sessionSearch.toggle();
        },
        useHotKey(args, search) {
			this.searchTerms.Date = [];
			this.$store.dispatch('SessionsModule/getFieldsForSearch', search.SearchParameters.TemplateName)
				.then(() => {
					this.$store.commit('SessionsModule/setSelectedTemplate', search.SearchParameters.TemplateName)
					this.selectedSavedSearch = search.Number;
		
					this.searchTerms.AdvancedSearch = search.SearchParameters.AdvancedSearch;
					this.searchTerms.AssetID = search.SearchParameters.AssetID;
					this.searchTerms.AssetLocation = search.SearchParameters.AssetLocation;
					this.searchTerms.EndDate = search.SearchParameters.EndDate;
					this.searchTerms.EndID = search.SearchParameters.EndID;
					this.searchTerms.FlowDirection = search.SearchParameters.FlowDirection;
					this.searchTerms.ProjectName = search.SearchParameters.ProjectName;
					this.searchTerms.StartDate = search.SearchParameters.StartDate;
					this.searchTerms.StartID = search.SearchParameters.StartID;
					this.searchTerms.TemplateName = search.SearchParameters.TemplateName;
					this.searchTerms.Date = [];
					this.searchTerms.Date[0] = new Date(search.SearchParameters.StartDate);
					this.searchTerms.Date[1] = new Date(search.SearchParameters.EndDate);
		
					if (search.SearchParameters.AdvancedSearchJson !== '') {
						this.$store.commit('SessionsModule/setAdvancedSearchRules', JSON.parse(search.SearchParameters.AdvancedSearchJson));
					} else {
						this.$store.commit('SessionsModule/setAdvancedSearchRules', DMUtils.copy(this.defaultAdvancedSearchRules));
					}
				})
		},
    },
    created() {
		this.$store.commit('setUseNavigationWarning', false);
		this.$store.commit('SessionsModule/setIsEditModeEnabled', false);
		this.$store.dispatch('SessionsModule/clearStore')
			.then(() => {
				return this.$store.dispatch('getTemplates', true)
			})
			.then(() => {
				return this.$store.dispatch('SessionsModule/getDirections')
			})
			.then(() => {
				this.searchTerms = this.getLocalStorageSearchTerms();
				this.sortOptions = { columns: [{ field: this.searchTerms.SortByColumn, direction: this.searchTerms.SortDirection }] };

				return this.$store.dispatch('SessionsModule/getFieldsForSearch', this.searchTerms.TemplateName);
			})
			.then(() => {
				return this.$store.dispatch('SessionsModule/getSessionsForSearch', this.searchTerms);
			})
			.then(() => {
				this.$store.commit('SessionsModule/setSelectedTemplate', this.searchTerms.TemplateName);
				this.$store.dispatch('SessionsModule/getSavedSearches');
				this.setSearchTermsString();

				if (localStorage.getItem('posm-autoCompleteValues')) {
					this.autoCompleteValues = JSON.parse(localStorage.getItem('posm-autoCompleteValues'));
				}

				if (localStorage.getItem('posm-advancedSearch')) {	
					this.$store.commit('SessionsModule/setAdvancedSearchRules', JSON.parse(localStorage.getItem('posm-advancedSearch')));
				}
			});
    },
};
</script>
