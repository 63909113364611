import observationsEndpoints from './observations.endpoints';

const observationsModule = {
    namespaced: true,
    state: () => ({
        Session: {
            SessionID: -1,
            Observations: [],
        },
        Observations: [],
        ObservationImages: [],
        ObservationCategoryOptions: [],
        ObservationTypeOptions: [],
        PipeNodes: [],
        PipeFlow: '',
        Hotbuttons: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
		SelectedPipeNode: null,
    }),
    mutations: {
        setSession(state, session) {
            state.Session = session;
        },
		setSelectedPipeNode(state, node) {
			state.SelectedPipeNode = node;
		},
        setObservations(state, observations) {
            state.Observations = observations;
        },
        setObservationCategoryOptions(state, observationCategoryOptions) {
            state.ObservationCategoryOptions = observationCategoryOptions;
        },
        setObservationTypeOptions(state, observationTypeOptions) {
            state.ObservationTypeOptions = observationTypeOptions;
        },
        setPipeNodes(state, pipeNodes) {
            state.PipeNodes = pipeNodes;
        },
        setPipeFlow(state, pipeFlow) {
            state.PipeFlow = pipeFlow;
        },
        setObservationImages(state, images) {
            state.ObservationImages = images;
        },
        addObservationVideo(state, video) {
            state.Videos.push(video);
        },
        setHotbuttons(state, hotbuttons) {
            state.Hotbuttons = hotbuttons;
        },
        clearModalData(state) {
            state.SeverityTypeOptions = [];
            state.ObservationCategoryOptions = [];
        },
    },
    actions: {
        getObservations({ commit }, sessionID) {
            return observationEndpoints.getAllObservations(sessionID).then((response) => {
                commit('setObservations', response);
            });
        },
        getObservationById({ commit }, observationID) {
            return observationEndpoints.getObservationByID(observationID).then((observation) => {
                commit('setObservationImages', observation.Images);
                return observation;
            });
        },
        createObservation({ dispatch }, dto) {
            return observationEndpoints.createObservation(dto).then(() => {
                return Promise.all([
                    dispatch('getObservations', dto.SessionID),
                    dispatch('getPipeNodes', dto.SessionID),
                ]);
            });
        },
        editObservation({ dispatch }, dto) {
            return observationEndpoints.editObservation(dto).then(() => {
                return Promise.all([
                    dispatch('getObservations', dto.SessionID),
                    dispatch('getPipeNodes', dto.SessionID),
                ]);
            });
        },
        deleteObservation({ dispatch, state }, dto) {
            return observationEndpoints.deleteObservation(dto).then(() => {
                return Promise.all([
                    dispatch('getObservations', state.Session.SessionID),
                    dispatch('getPipeNodes', state.Session.SessionID),
                ]);
            });
        },
        getAllObservationCategoryOptions({ commit }, templateName) {
            return observationEndpoints.getAllObservationCategoryOptions(templateName).then((response) => {
                commit('setObservationCategoryOptions', response);
                return response;
            });
        },
        getAllObservationTypeOptions({ commit, state }, args) {
            return observationEndpoints
                .getFaultCodesForTemplate(state.Session.TemplateName, args.Category, args.ObservationID)
                .then((response) => {
                    commit('setObservationTypeOptions', response);
                });
        },
        getPipeNodes({ commit }, sessionID) {
            return observationEndpoints.getPipeNodes(sessionID).then((response) => {
                response.PipeSegments.forEach((node, index) => {
                    if (node.Observations.length == 0) {
                        node.FileName = 'NoFault.svg';
                    } else {
                        let severity = 0;
                        let direction = '';

                        severity = node.Observations[0].Severity;
                        direction = node.Observations[0].Direction;

                        node.FileName = 'Fault' + direction;

                        if (severity != '0') {
                            node.FileName += '-' + severity;
                        }

                        node.FileName += '.svg';

                        node.Direction = direction;
                    }
                });

                commit('setPipeNodes', response.PipeSegments);
                commit('setPipeFlow', response.FlowDirection);
                return response.PipeSegments;
            });
        },
        getObservationInputs({}, faultCode) {
            return observationEndpoints.getObservationInputs(faultCode).then((response) => {
                return response;
            });
        },
        getNextContinuousValue({}, sessionID) {
            return observationEndpoints.getNextContinuousValue(sessionID).then((response) => {
                return response;
            });
        },
        beginEditMode({}, sessionID) {
            return observationEndpoints.beginEditMode(sessionID).then((response) => {
                return response;
            });
        },
        endEditMode({}, dto) {
            return observationEndpoints.endEditMode(dto).then((response) => {
                return response;
            });
        },

        // Images & Videos

        addImage({ }, dto) {
            return observationEndpoints.addImageToObservation(dto)
			.then((response) => {
				return response;
            });
        },
        editImage({ }, dto) {
            return observationEndpoints.editImageForObservation(dto);
        },
        deleteImage({ dispatch }, dto) {
            return observationEndpoints.deleteImageForObservation(dto)
			.then(() => {
                dispatch('getObservationById', dto.ObservationID);
            });
        },
        addVideo({ commit, dispatch }, {data, sessionID}) {
			return observationsEndpoints.addVideoToSession(data, sessionID)
				.then(() => {
					return dispatch('SessionsModule/getSessionByID', sessionID, {root: true})
				})
				.then((response) => {
					commit('setSession', response);
				});
        },
        deleteVideo({ dispatch, commit, state }, videoID) {
            return observationEndpoints.deleteVideoForSession(videoID)
				.then(() => {
					return dispatch('SessionsModule/getSessionByID', state.Session.SessionID, {root: true})
				})
				.then((response) => {
					commit('setSession', response);
				});
        },

        // Hotbuttons

        getHotbuttons({ commit, state }, templateName) {
            return observationEndpoints.getHotbuttons(state.Session.TemplateName).then((response) => {
                commit('setHotbuttons', response);
            });
        },
        setHotbutton({ dispatch, state }, hotButton) {
            return observationEndpoints.setHotbutton(hotButton).then((response) => {
                return dispatch('getHotbuttons', state.Session.TemplateName);
            });
        },
        deleteHotbutton({ dispatch, state }, hotButton) {
            return observationEndpoints.deleteHotbutton(hotButton).then((response) => {
                return dispatch('getHotbuttons', state.Session.TemplateName);
            });
        },

        clearModalData({ commit }) {
            return commit('clearModalData');
        },
    },
};

export default observationsModule;
