<template src="./manageVideosModal.html"></template>

<script>
import dmModalMixin from '../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'manage-videos-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            videoUploadColumns: [],
            videoTableActionItems: [],
            videoUploadTableActions: [
                    {
                        text: 'Edit',
                    },
                    {
                        text: 'Delete',
                    }
            ],
            observationsManageVideosTableSortBy: {},
            observationsManageVideosTableCustomClass: '',
            selectedRow: null,
        }
    },
    computed: {
        videoTableItems() {
            return this.$store.state.ObservationModule.Session.SessionVideoLocations;
        },
    },
    methods: {
        addVideo() {
            let modalName = 'addVideoModal';
            let modalID = modalName + DMUtils.getRandomGuid();
            let modalData = {};

            let dto = { modalName, modalID, modalData };
            this.$store.commit('ModalModule/openModal', dto);
        },
        fileUploaded(e) {
            let uploadedFile = e.target.files[0];

            let fileExtension = uploadedFile.type.replace(/(.*)\//g, '');

            this.dto.rawUploadedFile = uploadedFile;
            this.dto.Name = uploadedFile.name;

            var reader = new FileReader();

            let that = this;
            reader.onload = function (e) {
                that.dto.ImageSrc = reader.result;
            };

            reader.readAsDataURL(uploadedFile);
        },
        observationManageVideosTableRowOnClick(args) {
            this.selectedRow = args;
        },
        tryDeleteVideo() {
            const message = 'Are you sure you want to delete this video?';
            const title = 'Confirm';
            const confirmCallback = this.deleteVideo;

            DMUtils.openApplicationModal('dmConfirmModal', {
                title: title,
                message: message,
                confirmCallback: confirmCallback,
                confirmText: 'Yes',
                denyText: 'Cancel',
            });
        },
        deleteVideo() {
            let videoID = this.selectedRow.rowData.VideoID;
            this.$store.dispatch('ObservationModule/deleteVideo', videoID);
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        this.videoUploadColumns = [
            {
                type: 'iconTemplate',
                iconName: 'trash',
                iconOnClick: this.tryDeleteVideo,
                width: '15px',
            },
            {
                key: 'VideoName',
                label: 'Video Name',
                textAlign: 'Left',
                width: '150px',
            },
        ];
    },
}
</script>