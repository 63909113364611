import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import sessionsEndpoints from '../views/sessionManagement/sessions.endpoints.js';
import authorizationModule from '../../dmFramework/dmJS/dmAuthorizationModule.store.js';
import navigationModule from '../../dmFramework/dmJS/dmNavigationModule.store.js';
import modalModule from '../../dmFramework/dmJS/dmModalModule.store.js';
import observationsModule from '../views/observations/observations.store.js';
import sessionsModule from '../views/sessionManagement/sessionsStore.js';
import usersModule from '../views/userManagement/users.store.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        AuthorizationModule: authorizationModule,
        NavigationModule: navigationModule,
        ModalModule: modalModule,
        ObservationModule: observationsModule,
        SessionsModule: sessionsModule,
        UsersModule: usersModule,
    },
    state: {
        localStoragePrefix: 'posm-',
        DropdownFields: { text: 'Text', value: 'Value' },
		Templates: [],
		UseNavigationWarning: false,
        //IsSidebarVisible: null, // Nullable boolean for overriding default visibility based on page route
		GisAssetID: null,
		IsSidebarOpen: false,
    },
	mutations: {
		setTemplates(state, templates) {
            state.Templates = templates;
        },
		setUseNavigationWarning(state, warning) {
            state.UseNavigationWarning = warning;
        },
		setGisAssetID(state, id) {
			state.GisAssetID = id;
		},
		setIsSidebarOpen(state, isOpen) {
			state.IsSidebarOpen = isOpen;
		}
	},
	actions: {
		getTemplates({ commit }, includeAny = false) {
            return sessionsEndpoints.getTemplates(includeAny)
				.then((response) => {
					commit('setTemplates', response);
				});
        },
	}
});

export default store;
