<template src="./usersEditModal.html"></template>

<script>
import dmModalMixin from '../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'users-edit-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            lblTitle: 'Add User',
            dto: {},
            userRoleOptions: [
				{ Text: 'Admin', Value: 'Admin' },
                { Text: 'User', Value: 'User' },
                { Text: 'View-Only', Value: 'View-Only' },
                { Text: 'Guest', Value: 'Guest' },
            ],
        };
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
		userRole() {
			return this.$store.state.AuthorizationModule.SignedInUser.Role;
		}
    },
    methods: {
        btnSaveOnClick() {
			let dispatch = 'UsersModule/';
            if (this.modalData.mode === 'Add') {
				dispatch += 'addUser';
            }

            if (this.modalData.mode === 'Edit User') {
				dispatch += 'updateUserByAdmin'
            }

			if (this.modalData.mode === 'Edit Password') {
				dispatch += 'updatePasswordByAdmin';
			}

			this.$store.dispatch(dispatch, this.dto)
				.then(() => {
					this.successClose();
				})
        },
        btnCancelOnClick() {
            this.closeModal();
        },
    },
    created() {
        if (this.mode == 'Edit User') {
            this.lblTitle = 'Edit User';
        }

		if (this.mode == 'Edit Password') {
			this.lblTitle = 'Edit Password';
		}

        this.dto = DMUtils.copy(this.modalData.dto);
    },
};
</script>
