import store from '../../src/js/store';
import router from '../../src/router.js';

export default {
    //#region - Global Variables 

    Requests: 0,
    AuthToken: null,

    //#endregion - Global Variables

    //#region - HTTP Requests 

    http(config, hideLoader) {
        const appServer = window.apiRoot;

        if (!hideLoader) {
            this.addRequest();
        }

        if (config.url) {
            config.url = appServer + config.url;
        } else {
            config.url = appServer;
        }

        if (this.AuthToken !== null) {
            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + this.AuthToken;
        }

        return axios(config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleServerError(error);
                return Promise.reject(error);
            })
            .finally(() => {
                if (!hideLoader) {
                    this.removeRequest();
                }
            });
    },
    setAuthToken(authToken) {
        const authLocalStorageName = store.state.localStoragePrefix + 'AuthToken';
        this.localStorageSet(authLocalStorageName, authToken);
        this.AuthToken = authToken;
    },
    getAuthToken() {
        const authLocalStorageName = store.state.localStoragePrefix + 'AuthToken';
        return this.localStorageGet(authLocalStorageName);
    },
    clearAuthToken() {
        const authLocalStorageName = store.state.localStoragePrefix + 'AuthToken';
        this.localStorageRemove(authLocalStorageName);
        this.AuthToken = null;
    },
    sendErrorToDelMarMessaging(data) {
        return axios({
            method: 'POST',
            url: 'https://dev-server.delmarsd.com/DelMarMessaging/Messaging/FrontEndLog',
            data: data
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            this.handleServerError(error);
        });
    },
    handleServerError(data) {
        let response = data.response;
        let unexpectedErrorTitle = 'System Error:';
        let unexpectedErrorMessage = 'An unexpected error has occurred on the server.';

        if (response) {
            if (response.status >= 400 && response.status < 500) {
                let errorString = '';

                if (response.config.responseType === 'arraybuffer') {
                    try {
                        response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data)));
                    } catch (err) {
                        this.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
                    }
                }

                errorString = response.data.Error;

                let callback = () => {
                    if (response.status === 401) {
                        DMUtils.clearAuthToken();
                        router.push({ name: 'signin' })
                    }
                };

                this.alertShow(errorString, 'Error', callback);
            } else {
                this.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
            }
        } else {
            this.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
        }

        return Promise.reject(response);
    },
    downloadAjaxResponseAsFile(blob, fileName) {
        // Example of how to create blob for different content types
        // let blob = new Blob(response.FileData, { type: response.ContentType });
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = objectUrl;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    },
    objectToQueryString(params) {
        return Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&');
    },
    addRequest() {
        this.Requests++;
        this.spinnerToggleForRequest(true);
    },
    removeRequest() {
        this.Requests--;
        this.spinnerToggleForRequest(false);
    },

    //#endregion - HTTP Requests

    //#region - Modals and Spinners 

    spinnerToggleForRequest(show) {
        if (show && this.Requests === 1) {
            this.spinnerShow();
        }

        if (this.Requests === 0) {
            this.spinnerHide();
        }
    },
    spinnerShow() {
        let loading = document.createElement('div');
        loading.id = 'dm-spinner-active';
        loading.innerHTML = `<div class='dm-flex-column'><div></div>Loading...</div>`;
        loading.classList.add('dm-spinner');
        loading.style.display = 'flex';
        document.querySelector('body').appendChild(loading);
    },
    spinnerHide() {
        document.getElementById('dm-spinner-active').remove();
    },
    openApplicationModal(modalName, modalData) {
        const modalID = modalName + DMUtils.getRandomGuid();
        store.commit('ModalModule/openModal', { modalName, modalID, modalData });
    },
    confirmShow(message, title, confirmCallback, denyCallback, confirmText, denyText) {
        const modalComponent = {
            modalName: 'dmConfirmModal',
            modalID: 'dmConfirmModal' + DMUtils.getRandomGuid(),
            modalData: {
                title,
                message,
                confirmCallback,
                denyCallback,
                confirmText,
                denyText
            }
        };

        store.commit('ModalModule/openModal', modalComponent);
    },
    alertShow(message, title, callback) {
        const modalComponent = {
            modalName: 'dmAlertModal',
            modalID: 'dmAlertModal' + DMUtils.getRandomGuid(),
            modalData: {
                title,
                message,
                callback,
            },
        };
        store.commit('ModalModule/openModal', modalComponent);
    },
    confirmClose(closeCallback) {
        this.confirmShow(
            'You have unsaved changes. Are you sure you want to close?',
            'Confirm',
            closeCallback
        );
    },

    //#endregion - Modals and Spinners

    //#region - Local Storage 

    localStorageSet(key, value) {
        let type = typeof value;
        value = (type === 'Object' || type === 'Array') ? JSON.stringify(value) : value;
        localStorage.setItem(key, value);
    },
    localStorageGet(key) {
        return localStorage.getItem(key) || null;
    },
    localStorageRemove(key) {
        localStorage.removeItem(key);
    },

    //#endregion - Local Storage

    //#region - Get, Sort, Comparison, and Format Helper Functions 

    getRandomGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    getCboOptions(options, value, text, noSort) {
        if (value) {
            options = options.map((r) => {
                return {text: r[text], value: r[value]}
            });
        } else {
            options = options.map((r) => {
                return {text: r, value: r}
            });
        }

        if (noSort) {
            return options;
        } else {
            return this.sortObjectArray(options, 'text');
        }
    },
    sortObjectArray(array, property) {
        let compare = (a, b) => {
            if (a[property] < b[property]) {
                return -1;
            } else if (a[property] > b[property]) {
                return 1;
            } else {
                return 0;
            }
        };

        return array.sort(compare);
    },
    sortArray(array) {
        let compare = (a, b) => {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        };

        return array.sort(compare);
    },
    isNumTruthy(number) {
        return number !== undefined && number !== '' && !isNaN(parseFloat(number));
    },
    objectAreNotEqual(obj1, obj2, props = null) {
        if (props) {
            let isNotEqual = false;
            props.forEach((p) => {
                if (JSON.stringify(obj1[p]) !== JSON.stringify(obj2[p])) {
                    isNotEqual = true;
                }
            });

            return isNotEqual;
        } else {
            // Note: If the order of the properties are different, this will say the objects are different

            return JSON.stringify(obj1) !== JSON.stringify(obj2);
        }
    },
    callMultipleEndpoints(arrayOfEndpoints) {
        return Promise.all(arrayOfEndpoints)
            .then((allResponses) => {
                return allResponses;
            });
    },
    checkForChangesBeforeClose(obj1, obj2, callback) {
        if (this.objectAreNotEqual(obj1, obj2)) {
            this.confirmClose(callback);
        } else {
            callback();
        }
    },
    copy(data) {
        return JSON.parse(JSON.stringify(data));
    },
    round(number, decimalPlaces) {
        let precision = 10;
        for (let x = precision; x >= decimalPlaces; x--) {
            number = number.toFixed(x);
            number = parseFloat(number);
        }

        return number;
    },
    isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    displayNumber(number, decimalPlaces) {
        let roundedNumber = this.round(number, decimalPlaces);
        return roundedNumber.toLocaleString(undefined, {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
        });
    },
    formatDateDisplay(dateString) {
        if (!dateString) {
            return null;
        }

        return moment.utc(dateString).format('M/D/YYYY');
    },
    formatTimeDisplay(dateString) {
        if (!dateString) {
            return null;
        }

        return moment.utc(dateString).format('h:mma');
    },
    formatDateInput(dateString) {
        if (!dateString) {
            return null;
        }

        return moment.utc(dateString).format('YYYY-MM-DD');
    },
    formatDateTimeDisplay(dateString) {
        if (!dateString) {
            return null;
        }

        return moment.utc(dateString).format('M/D/YYYY hh:mmA z');
    },
    formatTimeInput(dateString) {
        if (!dateString) {
            return null;
        }

        return moment.utc(dateString).format('HH:mm');
    },
    getCurrentYear() {
        return moment().year();
    },

    //#endregion - Get, Sort, Comparison, and Format Helper Functions
};
