<template src="./DropdownButton.html"></template>

<script>

// Component Props Documentation
    // Text: Button Text
    // OnClick: () => {}
    // Options:
        // [ 
            // {
                // Text:
                // Value:
                // OnClick: () => {}
            //}
        //]

export default {
    name: 'dropdown-button',
    props: ['text', 'options', 'size', 'brandColor', 'onClick'],
    computed: {
        buttonColor() {
            if (this.brandColor == 'primary') {
                return 'app-background-brand-color-primary'
            }
            else if (this.brandColor == 'secondary') {
                return 'app-background-brand-color-secondary';
            }
            else if (this.brandColor == 'secondary-alternate') {
                return 'app-background-brand-color-secondary-alternate';
            }
        },
        buttonCssClasses() {
            let cssClasses = `e-caret-hide ${this.buttonColor}`;

            if (this.size == 'sm') {
                cssClasses += 'posm-input-sm';
            }
            else if (this.size == 'md') {
                cssClasses += 'posm-input-md';
            }
            else if (this.size == 'lg') {
                cssClasses += 'posm-input-lg';
            }

            return cssClasses;
        }
    },
    methods: {

    },
};
</script>
