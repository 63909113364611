<template>
    <ejs-dialog
        :id="modalName"
        :ref="modalName"
        :target="sfTarget"
        :header="title"
        isModal="true"
        visible="false"
        showCloseIcon="true"
        allowDragging="true"
        :width="'300px'"
        :beforeClose="preventClose">

        <div class="flex-column">
            <div class="dm-modal-form-content">
                {{ message }}
            </div>

            <div class="dm-flex-row dm-justify-content-flex-end dm-margin-top-md">
                <ejs-button
                    @click.native="btnConfirmOnClick"
                    cssClass="app-btn-primary">
                    {{ confirmText }}
                </ejs-button>

                <ejs-button
                    @click.native="btnCloseOnClick"
                    cssClass="app-btn-danger dm-margin-left-sm">
                    {{ denyText }}
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
    import modalMixin from './dmModalMixin'

    export default {
        name: 'dm-confirm-modal',
        mixins: [modalMixin],
        computed: {
            title() {
                return this.modalData.title;
            },
            message() {
                return this.modalData.message;
            },
            confirmText() {
                return this.modalData.confirmText || 'Yes';
            },
            denyText() {
                return this.modalData.denyText || 'No';
            }
        },
        methods: {
            btnConfirmOnClick() {
                if (typeof this.modalData.confirmCallback === 'function') {
                    this.modalData.confirmCallback();
                }
                
                this.closeModal();
            },
            btnCloseOnClick() {
                if (typeof this.modalData.denyCallback === 'function') {
                    this.modalData.denyCallback();
                }

                this.closeModal();
            }
        }
    }
</script>